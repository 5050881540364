/*View all*/
.flex-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  float: left;
  padding: 0 12px;
}
.flex-dev img {
  width: 100%;
}
.episodes-info {
  margin-top: 5px;
}
.episodes-info span {
  font-family: "Roboto", Arial;
  color: #fff;
  font-size: 13px;
  display: block;
  margin-bottom: 0px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
  cursor: pointer;
}
.viewall_rectangle{
  margin-top: 80px;
  padding: 0 55px;
  text-align: justify;
}
.footer_details{margin-top: 80px;padding: 0 55px;}

.viewall_rectangle .heading-video__title h1, .footer_details .footer_details_title h1{
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 22px;
  color: #fff;
  text-transform: capitalize;
  top: 0;
  line-height: normal;
  /* margin: 0px 0px 8px 0px; */
  padding: 0;
  /* padding-left: 10px; */
}
.viewall_title h2 {
  font-family: Helvetica-Bold, sans-serif;
  font-size: 24px;
  color: #666;
  text-transform: capitalize;
  font-weight: 700;
  top: 0;
  line-height: normal;
  margin: 0;
  padding: 0 10px;
}
.flex-dev {
  width: 13%;
  margin: 0.5%;
  cursor: pointer;
}
.viewall_titles{
  font-size: 18px;
  color:#ccc;
  padding-left: 20px;
}
.viewall_backto{
  margin-left: 15px;
}

/*Filters*/

.form_search_container {
  margin: 15px 0 0;
}
.form_container {
  margin-bottom: 20px;
}
.pos-relative {
  position: relative;
}
.clearfix:after,
.clearfix:before {
  content: " ";
  display: table;
}
.heading-video {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 10px 0;
}
.heading-video__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.form_container h2 {
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  color: #666;
  text-transform: capitalize;
  top: 0;
  line-height: normal;
  margin: 0;
  padding: 0;
}
.list-filter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.inside-page-filters .list-filter > .list-inline {
  display: flex;
  justify-content: flex-end;
  padding-top: 0;
}
.inside-page-filters .list-filter > .list-inline > li {
  float: left;
  text-align: center;
}
.list-filter > .list-inline > li,
.padding-0 {
  padding: 0;
}
.inside-page-filters .list-filter .filter-trigger {
  font-weight: 500;
}
.list-filter .filter-trigger {
  color: #666;
  display: flex;
  align-items: center;
  position: relative;
}
.list-filter .filter-trigger {
  font-size: 18px;
  font-weight: 600;
  background: 0 0;
  border: none;
  outline: 0;
  margin-left: 40px;
}
.list-filter .filter-trigger span {
  font-family: 'Roboto', sans-serif;
  display: inline-block;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
}
.open .filter-arrow {
  border: 10px solid transparent;
  border-bottom: 10px solid #666;
  transform: translateY(-10px);
}
.dropdown-menu-full {
  width: 100%;
  background: #2e2e2e;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  /* display: none; */
  float: left;
  min-width: 160px;
  padding: 10px 10px 10px 60px;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 0px solid #dadada;
  border-bottom: 0;
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.list-inline {
  margin-left: -5px;
}
.dropdown-menu-full ul > li {
  width: 16.667%;
  float: left;
  margin: 15px 0;
}
.list-inline > li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}
/*.clip-check input[type=checkbox] {
    display: none;
  }*/
.clip-check label {
  color: #fff;
  padding-left: 30px !important;
  margin-left: 0;
}
.filter-arrow {
  border: 10px solid transparent;
  border-top: 10px solid #666;
  margin-top: 10px;
  margin-left: 10px;
}
.under-filter-text {
  position: absolute;
  top: 26px;
  left: 0;
  width: 135px;
  text-transform: capitalize;
  display: block;
}
.list-filter .filter-trigger {
  font-size: 18px;
  font-weight: 600;
  background: 0 0;
  border: none;
  outline: 0;
  margin-left: 40px;
}
.list-filter .filter-trigger {
  color: #666;
  display: flex;
  align-items: center;
  position: relative;
}
.dropdown-menu-full .dropdown-footer {
  padding: 25px 0 15px;
}
.btn-default.active.focus,
.btn-default.active:focus,
.btn-default.active:hover,
.btn-default:active.focus,
.btn-default:active:focus,
.btn-default:active:hover,
.open > .btn-default.dropdown-toggle.focus,
.open > .btn-default.dropdown-toggle:focus,
.open > .btn-default.dropdown-toggle:hover {
  /* color: #333; */
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}
.btn,
.btn:focus,
.btn:hover {
  background: 0 0;
  padding: 6px 26px;
  border: 2px solid #fff;
  font-size: 14px;
  border-radius: 20px !important;
  font-weight: 700;
  color: #fff;
  min-width: 120px;
  outline: 0 !important;
  box-shadow: none !important;
  margin: 0 35px;
}
.btn-red,
.btn-red:focus,
.btn-red:hover {
  background: #eb1e27;
  color: #fff;
  border-color: #eb1e27;
}
/*check boxes*/
.clip-check label {
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  margin-right: 15px;
  padding-top: 4px;
  position: relative;
  line-height: 20px;
  transition: border 0.2s linear 0s, color 0.2s linear 0s;
}
.clip-check label {
  color: #fff;
  padding-left: 30px !important;
  margin-left: 0;
}
.clip-check label:before {
  content: "";
  background-color: #2e2e2e;
  border: 2px solid #fff;
}
.clip-check label:after,
.clip-check label:before {
  height: 18px;
  left: 0;
  top: 5px;
  width: 18px;
}
.clip-check label:before {
  margin-right: 10px;
  border-radius: 3px;
}
.clip-check label:after,
.clip-check label:before {
  display: inline-block;
  position: absolute;
  transition: border 0.2s linear 0s, color 0.2s linear 0s;
}
*,
:after,
:before {
  box-sizing: border-box;
}
.clip-check input[type="checkbox"]:checked + label:before {
  border-width: 9px;
  border-color: #eb1e27;
}
.clip-check label:before {
  content: "";
  background-color: #2e2e2e;
  /* border: 2px solid #fff; */
}
.clip-check label:after,
.clip-check label:before {
  height: 18px;
  left: 0;
  top: 5px;
  width: 18px;
}
.clip-check input[type="checkbox"]:checked + label:after {
  color: #fff;
  content: "\f00c";
  font-family: FontAwesome;
}
.clip-check label:after {
  font-size: 13px;
  text-align: center;
}
.clip-check input[type="checkbox"] {
  display: none;
}

/** Infinity loader **/
.spinner {
  margin: 0px auto;
  width: 100%;
  text-align: center;
  padding: 5px 0px;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #281a59;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
/** Player Section **/
.player_section {
  margin-top: 50px;
  width: 100%;
  float: left;
  position: relative;
  margin-bottom: 10px;
}
.padding_rmv {
  padding: 0px !important;
}
.movies_detail_content {
  width: 100%;
  float: left;
  /* background-color: #121212; */
  padding-left: 30px;
}
.movies_detail_content .movies_detail_title h1 {
  font-family: "Roboto", Arial;
  font-weight: 500;
  font-size: 26px;
  text-transform: inherit;
  color: #fff;
  margin: 0px;
}
.movies_detail_content .movies_detail_title h4 {
  font-family: "Roboto", Arial;
  font-weight: 500;
  font-size: 24px;
  text-transform: inherit;                                          
  color: #fff;                                                                      
}                                                     
.movie_details-div ul {
  list-style: none;
  width: 100%;
  color: #3e3e3e;
  margin: 0;
  padding: 0px !important;
}
.movie_details-div ul li {
  font-family: "Roboto", Arial;
  font-size: 16px;
  color: #999;
  display: inline-block;
  text-transform: capitalize;
}
.capitalize {
  text-transform: capitalize;
}
.movie_details-div ul li + li:before {
  content: "|";
  padding: 0 5px;
}
.flow_player {
  width: 66.777778%;
}
.player_section .movie_details {
  display: flex;
  flex-wrap: wrap;
  color: #000;
  width: 33.33333333%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: auto;
  right: 0;
  margin: auto;
  text-align: left;
  height: 100%;
  padding: 20px;
  /* background: rgba(255, 255, 255, 0.9); */
  /* background-color: #121212; */
}
.share-video-block {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin-top: auto;
}
.share-video-block .share-btn-group {
  display: flex;
  flex-direction: inherit;
  font-size: 12px;
  padding-right: 10px;
}
.share-video-block .share-btn-group .play_trailer{
  margin-left: 10px;
}
.share-video-block .share-btn-group .play_trailer .play_trailer_icon{
  display: block;
  width: 15px;
  height: 15px;
  margin-right: 10px;
}
.play_trailer{width: 160px;}
.play_trailer a{
  display: flex;
  color: #fff;
}
.add-watchlist-btn {
  background: 0 0;
  color: #fff;
  padding: 10px 20px;
  border: 2px solid #3c3c3c;
  border-radius: 5px;
  font-weight: 700;
  transition: all 0.15s ease-in;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.add-watchlist-icon,
.added-watchlist-icon {
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.add-watchlist-icon_apex,
.added-watchlist-icon_apex {
  display: block;
  width: 35px;
  height: 35px;
  margin-right: 10px;
}
.video-socials-list {
  display: flex;
  align-items: center;
  padding-left: 0;
}
ul {
  list-style: none;
}
.video-socials-list__item {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: #3c3c3c;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  transition: all 0.15s ease-in;
}
.video-socials-list__link {
  color: #000;
  font-size: 20px;
  transition: all 0.15s ease-in;
}
.fa {
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}
.video-socials-list__item:hover {
  background-color: #eb1e27;
}
.video-socials-list__item a:hover {
  color: #fff;
}
.add-watchlist-btn:hover {
  background: #eb1e27;
  border: 2px solid #eb1e27;
}
.detail_synopsis {
  width: 100%;
  float: left;
}
.synopsis {

  padding: 0px;

}
.detail_synopsis .synopsis h2.title,
.you-may-like_section h2.title {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  text-transform: capitalize;
  top: 0;
  line-height: normal;
  margin-bottom: 10px;
  padding: 0;
  color: #fff;
}
.synopsis_content p {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #999;
  text-align: justify;
}
.you-may-like_section {
  /* display: flex;
  flex-wrap: wrap; */
  margin-top: 0px;
  width: 100%;
  float: left;
  /* padding: 0 55px; */
}
.episodes_section{ 
  padding: 0px 55px;
  /* background-color: #222; */
  width: 100%;
  float: left;
}
.count-episodes {
  font-family: "Roboto", Arial;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 0;
  color: #999;
  padding: 5px 0px 5px 0px;
}
.episodes_info__time {
  font-family: "Roboto", Arial;
  font-size: 12px;
  color: #999;
}
.select_options {
  color: #666;
  font-size: 16px;
  float: right;
  margin-right: 10px;
}
.select_options select {
  font-family: "Roboto", Arial;
  background-color: #121212;
  width: 150px;
  border: 1px solid #666;
  padding: 5px;
  color: #666;
  font-size: 13px;
}
.select_options select:focus {
  outline: none;
}
.select_options select option:hover {
  color: #eb1e27;
}
.player{width: 100%;
  float: left;}
.youtube-container{
  position:relative;
	padding-bottom:56.25%;
	padding-top:30px;
	height:0;
	overflow:hidden;
}
.youtube-container iframe, .youtube-container object, .youtube-container embed {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
}

.viewall_horizontal{margin-top: 80px;
  padding: 0 55px;}
.viewall_horizontal .flex-dev{width: 10%; 
    margin: 0.5%;}


.carosel_slider .owl-item img, .live_streaming img, .flex-dev img{ -webkit-transition: 0.6s ease;
  transition: 0.6s ease;}
.carosel_slider .owl-carousel.owl-drag .owl-item:hover .carousel_img img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    cursor: pointer;
}  
.carosel_slider .owl-carousel.owl-drag .owl-item .carousel_img, .live_streaming div{
    overflow: hidden;
}
.carosel_slider .owl-carousel.owl-drag .owl-item:hover .icon-caption .icon-caption-text h2, .carosel_slider .owl-carousel.owl-drag .owl-item:hover .icon-caption .icon-caption-text span,.live_streaming:hover h2{color: #eb1e27;}
.carosel_slider .owl-carousel.owl-drag .owl-item:hover a{
  text-decoration: none;
}

.carosel_slider .owl-carousel.owl-drag .owl-item:hover .carousel_img img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}  
.carosel_slider .owl-carousel.owl-drag .owl-item .carousel_img {
    overflow: hidden;
    border-radius: 8px;
}
.live_streaming{cursor: pointer;}
.live_streaming:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
} 
.viewall_rectangle .heading-video__title h1{padding-left: 10px; margin: 0px 0px 5px 0px;}

.flex-dev:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}  
.flex-dev div{
  overflow: hidden;
}


/* Ordersubscription */

.myinfo .container-fluid{
  width: 100%;
  font-size: 13px;
  float: left;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 15px !important;
}
 .vfplay-header{background: #eb1e27;
  color: #fff;
  position: fixed;
  width: 100%;
  float: left;
  top: 0;
  z-index: 1;
  padding: 10px 0px;}   
.vfplay-header h3{margin: 0;
  margin-left: 20px;}   
.user_details{
  float: left;
  color: #999;
}    
.user_data{
  word-wrap: break-word;
  float: right;
}   
.user_info{margin-bottom: 15px;
  width: 100%;
  float: left;}      
.container-fluid{
  color: #fff;
  padding-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
}
.container-fluid:last-child{
  margin-bottom: 0;
}
.device_align{float: right; 
  right: 20px; 
  position: relative;
}
.deregister{
  cursor: pointer;
  color:#eb1e27;
  float: right;
  font-size: 13px;
} 
.ordersubscriptions h4{    
  color:#ffcc33 !important;
}
.ordersubscriptions h3{
  color: #ccc !important;
}

/* .verticalimage {
  height: 90px !important;
} */
/* shorts */
/* * {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
} */

/* *::-webkit-scrollbar {
  display: none;
} */

/* html,
body {
  height: 100vh;
  overflow: hidden;
  color: #fff;
  font-family: 'Helvetica Neue', sans-serif;
} */


