.carousel_body {
  padding: 0 55px;
  float: left;
  width: 100%;
  margin-top: 5px;
}
.home_carosel_title h4 {
  display: inline-block;
  color: #999;
}
.home_viewall {
  font-family: "Roboto", Arial;
  color: #eb1e27;
  float: right;
  font-size: 16px;
  margin-top: 0px;
}

.home_viewall span:hover {
  color: #eb1e27;
}
.carousel_body .home_carosel_title h1 {
  line-height: normal;
  font-size: 18px;
  text-transform: capitalize;
  top: 4px;
  margin-bottom: 20px;
  padding: 0;
  color: #ffffff;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 250px;
  margin: 0px !important;
  display: inline-block;
}
.banner_slider{
  position: relative;
}
.banner_play_icon img{
  width: 43px !important;
}
.banner_play_icon{
  position: absolute;
  right: 15px;
  bottom: 30px;
  z-index: 1;
}
.banner_slider .bannerimage{
  border-radius: 15px;
}
.banner_slider .owl-dots {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  background: #ccc;
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background-color: #2CD7F3 ;
}
.banner_slider .owl-nav {
  margin: 0px;
}
.banner_slider .owl-prev {
  position: absolute;
  left: 0px;
  top: 0;
  bottom: 0;
  left: 0;
  width: 15%;
  font-size: 20px;
  color: #fff;
  text-align: center;
}
.banner_slider .owl-next {
  position: absolute;
  right: 0px;
  top: 0;
  bottom: 0;
  width: 15%;
  font-size: 20px;
  color: #fff;
  text-align: center;
}
.owl-theme .owl-nav [class*='owl-']{
  margin: 0px;
  padding: 0px;
}
/* .owl-theme .owl-nav .owl-prev, .owl-theme .owl-nav .owl-prev:hover{
  background-image: -webkit-linear-gradient(left,rgba(0,0,0,.8) 0,rgba(0,0,0,.3) 100%);
  background-image: -o-linear-gradient(left,rgba(0,0,0,.9) 0,rgba(0,0,0,.0001) 100%);
  background-image: -webkit-gradient(linear,left top,right top,from(rgba(0,0,0,.8)),to(rgba(0,0,0,.3))); 
  background-image: linear-gradient(to right,rgba(0,0,0,.8) 0,rgba(0,0,0,.3) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1);
  background-repeat: repeat-x;
  width: 24.5%;
}
.owl-theme .owl-nav .owl-next, .owl-theme .owl-nav .owl-next:hover{
    background-image: -webkit-linear-gradient(left,rgba(0,0,0,.3) 0,rgba(0,0,0,.8) 100%);
    background-image: -o-linear-gradient(left,rgba(0,0,0,.3) 0,rgba(0,0,0,.8) 100%);
    background-image: -webkit-gradient(linear,left top,right top,from(rgba(0,0,0,.0001)),to(rgba(0,0,0,.7)));
    background-image: linear-gradient(to right,rgba(0,0,0,.3) 0,rgba(0,0,0,.8) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
    background-repeat: repeat-x;
    width: 23.6%;
} */

.banner_slider .owl-theme .owl-nav [class*="owl-"] {
  font-size: 40px;
}
.owl-theme .owl-nav [class*="owl-"]:hover {
  background-color: transparent;
}
.owl-theme .owl-nav [class*="owl-"] {
  background-color: transparent;
}
.carousel_body .owl-prev {
  position: absolute;
  left: -35px;
  top: 40%;
  padding: 0 !important;
  margin: 0 !important;
}
.carousel_body .owl-next {
  position: absolute;
  right: -20px;
  top: 40%;
  padding: 0 !important;
  margin: 0 !important;
}
.carousel_body .owl-theme .owl-nav [class*="owl-"] {
  font-size: 35px;
}
.carousel_body .owl-prev.disabled,
.carousel_body .owl-next.disabled {
  display: none !important;
}
.carousel_leftarrow{
  background: url(../../assets/images/carousel_arrow_left_default.png);
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  z-index: 9;
  transform: translateX(-50%);
  opacity: 1;
  width: 25px;
  height: 25px;
  padding: 0;

}
.carousel_rightarrow {
  font-size: 0;
  background-color: transparent;
  /* background-image: url("https://www.vodafoneplay.in/images/asset8-1.svg"); */
  background: url(../../assets/images/carousel_arrow_right_default.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  z-index: 9;
  transform: translateX(-50%);
  opacity: 1;
  width: 25px;
  height: 25px;
  padding: 0;
}
/* .carousel_leftarrow {
  transform: rotate(180deg);
} */
.carousel_leftarrow:hover{
  background: url(../../assets/images/carousel_arrow_left_on_hover.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

}
.carousel_rightarrow:hover {
  /* background-image: url("https://www.vodafoneplay.in/images/asset8-1hover.svg"); */
  background: url(../../assets/images/carousel_arrow_right_on_hover.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.carosel_slider{
  width: 100%;
  float: left;
}
.carosel_slider .horizontalimage, .carosel_slider .verticalimage, .carosel_slider .newsNestedLayout {
  width: 100%;
  border-radius: 10px;
}
.carousel_img{
  position: relative;
  border-radius: 10px;
}
.carosel_slider .slick-track{
  float: left;
}
.carosel_slider {
  padding: 0px 0px 10px 0px;
}
.icon-caption {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.icon-caption-image {
  width: 60px !important;
  height: 60px;
  border-radius: 8px;
}
.icon-caption-text span {
  color: #666;
  font-family: "Roboto", Arial;
  font-weight: 500;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
  display: block;
}
.icon-caption-text {
  padding-top: 6px;
}
.icon-caption img {
  margin-right: 10px;
}
.icon-caption-text h2 {
  font-size: 18px;
  margin: 0px;
}
.icon-caption-text h2,
.icon-caption-text span {
  font-family: "Roboto", Arial;
  color: #666;
  font-weight: 500;
}
.icon-caption {
  padding: 8px 0px;
}
.live_streaming h2 {
  color: #777;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 160px;
  margin: 8px 0px 0px 0px;
  font-size: 16px;
  font-weight: 500;
}
.live_streaming span {
  font-family: "Roboto", Arial;
  font-size: 18px;
  color: #777;
  margin: 0px;
}
.live_streaming h3 {
  font-family: "Roboto", Arial;
  font-size: 16px;
  color: #777;
  margin: 0px;
}
.remove_item {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 10px;
  left: 10px;
  background-size: cover;
  cursor: pointer;
  z-index: 9;
}
.content_partner_logo {
  display: inline-block;
}
.home_carosel_title{ 
  padding: 5px 0px;
  position: relative;
}
.home_carosel_title img {
  margin-right: 10px;
}
.title_watchlist h2 {
  font-family: "Roboto", Arial;
  font-weight: 600;
  margin: 70px 0px 0px 55px;
  font-size: 22px;
  color: #999;
}
.flex-dev a:hover {
  text-decoration: none;
}
.viewall_section .flex-dev div {
  /* border-radius: 8px; */
}
.viewall_div{
  border-radius: 8px;
}
.genres_languages {
  border-radius: 8px;
}
/* .banner_slider .owl-carousel .owl-item img {
 border-radius: 8px;
} */
.count-episodes a{
  color: #fff;
  font-size: 14px;
}
.episodes_viewall{float: right;}
.episodes_viewall a{
  font-family: "Roboto", Arial;
  color: #2CD7F3 !important;
  float: right;
  font-size: 16px;
  margin-top: 5px;
}
.episodes_viewall a:hover{
  color: #2CD7F3 !important;
  text-decoration: none;}
.count-episodes a:hover{
    color: #999 !important;
    text-decoration: none;
}
.episodes_Heading{
  font-family: "Roboto", Arial;
  font-size: 20px;
  color: #999;
}
.trending_search img{
    width: 100%;
    height: 90px;
    object-fit: cover;
    border-radius: 8px;
}
.sliderbody{
  margin-top: 70px;
  width: 100%;
}
.carousel_titles{
  width: 100%;
  float: left;
  background-color: #343434;
  padding-left: 5px;
  text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.carousel_titles h1{
  font-size: 13px;
  color: #999;
  margin: 6px 0px 5px 0px;
  display: inline-block;
  padding-right: 10px;
  color:#FFFFFF
}
.banner_caption .rupee_symbol{
  color: #fff;
  padding: 0px 5px;
}
.you-may-like_section h2{
  width: 100%;
  font-size: 20px;
  float: left;
  margin: 0px;
}
.payment_amount{
  float: right;
  color: #fff !important;
  font-family: roboto;

}
.rupee{
  margin-bottom: 10px;
}

.payment_amount_price{
  float: right;
  color: #fff !important;
}
.banner_caption {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 5px;
  text-align: left;
  background:url(../../assets/images/gradient_overlay_hdpi.png) no-repeat scroll 0 0;
  background-size: cover;
  padding: 0px 5px;
}
.banner_caption h1{
  margin: 0;
  font-size: 14px;
  color: #fff;
  display: inline-block;
}
.banner_caption ul {
    padding: 0;
}
.banner_caption .breadcrumb_section{
  padding: 0px;
    margin: 0px;
  }
.banner_caption .breadcrumb_section ul li {
    color: #fff;
    font-size: 10px;
}
.widget-svg{
  height: 25px !important;
}
.app_rating .star-container{
  padding-right: 1px !important;
  padding-left: 1px !important;
}

/* .sliderbody {
  margin-top: 54px !important;
} */
/* banner prev/next */

.owl-carousel .nav-btn{
  height: 47px;
  position: absolute;
  width: 40px;
  cursor: pointer;
}
.layout_center_banner{
  padding: 15px 0px;
}
.layout_center_banner .slick-slider .slick-prev{
  background: url(../../assets/images/banner_arrow_left_default.png) no-repeat scroll 0 0;
  left: 80px;
  top: 48%;
  height: 45px;
}
.layout_center_banner .slick-slider .slick-next{
  background: url(../../assets/images/banner_arrow_right_default.png) no-repeat scroll 0 0;
  /* right: 0px; */
  top: 48%;
  height: 45px;
}
.layout_center_banner .slick-slider .slick-prev:hover{
  background: url(../../assets/images/banner_arrow_left_on_hover.png) no-repeat scroll 0 0 !important;
  /* left: 80px; */
  /* top: 43%;
  transform: scale(1.1); */
}
.layout_center_banner .slick-slider .slick-next:hover{
  background: url(../../assets/images/banner_arrow_right_on_hoverd.png) no-repeat scroll 0 0 !important;
  /* right: 80px; */
  /* top: 43%;
  transform: scale(1.1); */
}


.owl-carousel .carousel-nav-btn{
  height: 47px;
  position: absolute;
  width: 45px;
  cursor: pointer;
}
.owl-carousel .carousel-prev-slide{
  background: url(../../assets/images/carousel_arrow_left_default.png) no-repeat scroll 0 0;
  left: -4px;
  width: 28px;
}
.owl-carousel .carousel-next-slide{
  background: url(../../assets/images/carousel_arrow_right_default.png) no-repeat scroll 0 0;
  right: -33px;
}
.owl-carousel .carousel-prev-slide:hover{
  background: url(../../assets/images/carousel_arrow_left_on_hover.png) no-repeat scroll 0 0;
  left: -4px;
  width: 28px;
  transform: scale(1.0);
}
.owl-carousel .carousel-next-slide:hover{
  background: url(../../assets/images/carousel_arrow_right_on_hover.png) no-repeat scroll 0 0;
  right: -33px;
  transform: scale(1.0);
}

.owl-theme .owl-nav [class*='owl-']{
  border-radius: 0px;
}
.viewall_arrow{
   float: right;
   margin-top: 3px;
}
/* .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background-color: #2CD7F3 !important;
    padding: 0px 10px;
} */
.owl-theme .owl-dots .owl-dot.active span{
  background-color: #2CD7F3 !important;
  padding: 0px 10px;
}
.banner_slider #star-rating li {
  padding-right: 4px;
}
.layout_center_carusel .slick-slide img{
  width: 100%;
  border-radius: 8px;
}
.layout_center_carusel .center_caousel_item{
  padding: 0 20px;
}
.layout_center_carusel, .layout_center_banner {
  width: 100%;
  float: left;
}
.layout_center_carusel h3{
  margin-top: 10px;
}
.profile_radio_carousel{
  width: 100%;
  float: left;
  /* margin-top: 60px; */
}
.layout_center_banner .slick-slide img{
  width: 100%;
  border-radius: 16px;
  cursor: pointer;
}
.layout_center_banner .center_caousel_item {
  padding: 0 4%;
}
.layout_center_banner .slick-slider .slick-prev {
  left: -25px;
  z-index: 1;
}
.layout_center_banner .slick-prev:before, .layout_center_banner .slick-next:before{
  display: none ;
}
.layout_center_banner .slick-dots{
  position: relative;
  bottom: -12px;
}
.layout_center_banner .slick-slider .slick-dots li button::before{
  font-size: inherit;
}
.layout_center_banner .slick-dots li.slick-active button{
  background-color: #2cd7f3;
  border-radius: 10px;
  opacity: 1;
}
.layout_center_banner .slick-dots li button, .layout_center_banner .slick-slider .slick-dots li button::before{
  width: inherit;
  height: inherit;
  border-radius: 50%;
  background-color: #ccc;
}
.layout_center_banner .slick-dots li{
  width: 10px;
  height: 10px;
}
.layout_center_banner .slick-dots li.slick-active {
  opacity: 1;
  color: #2cd7f3 !important;
  height: 6px;
  width: 20px;
}

.profile_radio_carousel .profile_radio_circle{
  /* width: 50% !important;
  height: 50% !important; */
  border-radius: 50%;
  margin: 0px auto;
}
 .profile_radio_circle{
  width: 100px !important;
  height: 100px !important;
 
}
.profile_radio_section{
  width: 100%;
  background-color: #171717;
  border-radius: 8px;
  float: left;
  text-align: center;
  padding: 15px 0px;
}
.profile_radio_section h4{
  font-size: 13px;
  color: #fff;
  margin-bottom: 5px;
}
.profile_radio_section h5{
  font-size: 11px;
  color: #ccc;
  margin-top: 0px;
}
.desktop_fav_btn{
  background-color:#2CD7F3;
  border: none;
  border-radius: 20px;
  width: 60%;
  text-align: left;
  padding: 8px 10px;
  color:#fff;
  font-size: 13px;
  margin-bottom: 25px;
}
.desktop_fav_playlist{
  background-color: transparent;
  border: 1px solid#999;
}
.desktop_fav_btn img{
  width: 18px;
  margin-right: 10px;
}
.desktop_favourite_options ul{
  margin-top: 0px !important;
}
.desktop_favourite_options ul li{
  width: 30% !important;
  text-align: left !important;
  background-color: transparent !important;
  border: 1px solid#999;
  border-radius: 20px !important;
}
.desktop_favourite_options ul li img{
  float: left;
  margin-left: 10px;
  margin-right: 18px;
}
.desktop_favourite_options ul li p{
  text-align: left;
  padding-top: 0px !important;
}
.desktop_fav_playnow{
  margin-top: 40px;
}
.desktop_playlist_section .add_play_list {
  width: 60% !important;
  padding: 9px 10px !important;
}
.desktop_playlist_section .add_play_list p{
  text-align: left !important;
  margin-left: 40px;
}
.dropdown-menu hr{
   margin: 5px 0px;
   border-top: 1px solid #666;
}
.detail_news_carousels .carousel_body h4{
   color: #fff;
   font-size: 12px;
   line-height: 15px;
   margin: 5px 0px;
}
.detail_news_carousels .movie_details-div ul li{
  color: #fff;
  font-size: 11px;
}
.detail_news_carousels .movie_details-div ul li + li:before {
  content: ".";
  padding: 0 5px;
}
.detail_description_section{
  width: 100%;
  float: left;
  padding: 0px 55px;
  color:#fff;
}
.detail_description_section p{
 font-size: 12px;
}

.breaking_caption, .trailers_caption{
  position: absolute;
  bottom: 0px;
  left: 12px;
  overflow: hidden;
  height: 80px;
}
.breaking_caption p{
  color:#fff;
}
.trailers_caption{
  position: absolute;
  bottom: 0;
  left: 5px;
}
.trailers_caption p{
  color: #fff;
  font-size: 12px;
  left: 5px;
  margin: 0px;
  margin-bottom: 2px;
}
.detail_news_carousels .carousel_body img{
  border-radius: 5px;
}
.episodes_tabs .carousel_body{
  padding: 0px 0px;
}
.breaking_news_carousel{
  width: 100%;
  float: left;
}
.breaking_news_carousel .carousel_body{
  padding: 0px;
}
.episodes_section h4{
  color:#fff;
  font-size: 13px;
  font-weight: 400;
}
.author_section{
  width: 100%;
  float: left;
  /* background-color:#F1F2F6; */
  padding: 6px;
}
.author_section img{
  float: left;
}
.author_section h5{
  color: #FFFFFF;
  margin-left: 42px;
}
.ad-section{
  width: 100%;
  float: left;
  /* margin-top: 10px ; */
  text-align: center;
}
.premium_badge_lock{
  position: absolute;
  width: 24px;
  right: 5px;
  top: 5px;
}
.banner_premium_badge_lock{
  position: absolute;
  width: 24px;
  right: 5%;
  top: 4%;
}
.episodes_section .carousel_body{ 
 padding: 0px;
}
.parentControl{
  width: 100%;
  float: left;
  padding-top: 13px;
}
.player_parentcontrol{
  position: absolute;
  top: 10px;
  left: 15px;
  z-index: 9;
  padding: 10px 5px;
  cursor: pointer;
}
.subscribeuser{  
    top: 55%;
    left: 0;
    z-index: 9;
    right: 0;
    text-align: center;
    font-size: large;
}
.videojs_player_section .player_parentcontrol{
  display: none;
}
.videojs_player_section .subscribeuser{
  display: block;
}
.videojs_player_section .vjs-fullscreen .player_parentcontrol{
  display: block;
}
/* .videojs_player_section .vjs-600 .vjs-forward-control, .vjs-600 .vjs-logo-bar, .vjs-600 .vjs-mirror-button, .vjs-600 .vjs-mode-control, .vjs-600 .vjs-rewind-control{
  display: block;
} */
.rating_category_type{
  color:#999;
  background-color: #212121;
  border: 1px solid #ababab;
  padding: 2px 6px;
  border-radius: 3px;
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
}
.rating_category_name {
  color:#999;
  position: relative;
  left: 6px;
}
.subscription_plans_page, .mysubscriptions_page{
  width: 100%;
  float: left;
  margin: 50px 0px;
}
.mobile_search_filters{
  width: 100%;
  float: left;
  padding: 10px 0px;
}
.mobile_search_filters .menu_mobile{
  position: relative;
  top: 0;
}
.mobile_search_filters ul li {
  margin: 0px 10px;
  border: 1px solid #fff;
  border-radius: 20px;
}
.mobile_search_filters ul li span{
  color:#fff;
}
.mobile_search_filters .menu_mobile.vfplay_menu_mobile .menu_list_link.filter_active{
  border: none;
}
.mobile_search_filters .menu_mobile .menu_list_item.filter_active .menu_list_link span {
  color:#fff;
}
.mobile_search_filters .menu_mobile .menu_list_item.filter_active{
  background-color:#2CD7F3;
  border: 1px solid#2CD7F3;
}
.mobile_search_filters .menu_mobile.vfplay_menu_mobile{
  top: 0px;
}
.white-theme .mobile_search_filters .menu_mobile.vfplay_menu_mobile .menu-listres{
  background-color:#000 !important;
}
.footer_details.tandc_section {
  padding: 0 10px;
  min-height: 165px;
  margin-top: 70px;
}
.plans_section {
  cursor: pointer;
  padding: 8px 10px;
  border-bottom: 1px solid#444444;
}
.plans_section h1 {
  color: #ccc;
  font-size: 13px;
  margin-bottom: 12px;
  font-weight: 500;
}
.episodes_selection{
  float: right;
  margin-right: 15px;
}
.episodes_selection select{
  background-color:#171717;
  color:#fff;
  border: 1px solid #999!important;
  padding: 2px 5px;
}
.watched_carousel .episodes_section{
  padding: 0px;
  margin-top: 0px;
}
.recommended_carousel .episodes_section{
  margin-top: 0px;
}
.recommended_carousel img{
  border-radius: 6px;
}
.you-may-like_section .episodes_tabs{
  margin-top: 0px;
}
.singlebannerlivevideo{
  width: 100%;
  float: left;
}
.singlebannerlivevideo .promo_video{
  width: 60%;
  float: unset;
  margin: 0px auto;
}
.singlebannerlivevideo .promo_video h1{
  font-size: 18px;
  color:#fff;
  padding: 5px 0px;
  text-align: left;
}
.episodes_tabs .episodes-info span, .recommended_carousel .episodes-info span{
  width: 190px;
}
.profile_details_modal p{
  color:#b3b3b3;
  font-size: 13px;
  margin: 0px;
}
.profile_details_modal h4{
  color:#fff;
  font-size: 15px;
  margin-top: 0px;
}
.profile_details_modal .age_selection{
  width: 29%;
  float: left;
  margin: 0px 12px 18px 0px;
}
.profile_details_modal .modal-content{
  background-color: #282828 !important;
  border-radius: 15px 15px 0px 0px;
}
.profile_details_modal .age_selection .form-control{
  background-color: #282828;
  color:#b3b3b3;
  border: 1px solid#b3b3b3;
}
.age_section{
  width: 100%;
  float: left;
  margin-top: 20px;
}
.age_btn_skip{
  background-color: transparent !important;
  color: #fff !important;
  border: 1px solid transparent !important;
  text-decoration: underline;
  border: none !important;
  float: left;
  padding-top: 15px;
  margin-left: 45px;
}
.age_btn_section{
  padding: 0px 15px 20px 15px;
  text-align: center;
}
.age_btn_section button{
  margin-top: 6px;
}
.profile_details_modal .age_btn_section .age_btn_continue{
  background-color: #8c98af;
  color:#fff;
  border: 1px solid#8c98af;
}
.profile_details_modal .age_btn_section .age_btn_continue:hover{
  background-color:#8c98af;
  color:#fff;
  font-weight: 400;
}
.modal.profile_details_modal{
  width: 100%;
  top: inherit;
}
.profile_details_modal .modal-dialog{
  margin: 0px;
}
.profile_details_modal .address_selection .form-control{
  background-color:#282828;
  color:#b3b3b3;
  border: 1px solid#b3b3b3;
  margin-bottom: 18px;
}
.profile_details_modal .select_ctst .form-control{
  width: 48%;
  float: left;
}
.address_selection .select_state{
  margin-left: 10px;
}
.age_btn_section img{
   width: 14px;
   margin-left: 5px;
}
.continuewatch_carousel{
  width: 100%;
  float: left;
}
.breaking_news_carousel .carousel_body .owl-prev {
  left: 45px;
}
.breaking_news_carousel .carousel_body .owl-next {
  right: 45px;
}
.breaking_news_carousel .carousel_img img{
  border-radius: 10px;
}
.breaking_news_carousel .carousel_slider_section .slick-slider .slick-next{
  right: 30px;
}
.breaking_news_carousel .carousel_slider_section .slick-slider .slick-prev{
  left: 30px;
}
/* .carosel_slider .slick-slider .slick-slide:hover .carousel_img img{
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
} */
/* .banner_slider .owl-prev, .banner_slider .owl-next{
  display: none !important;
}  */
/*loader*/
.banner_title_name{
  display: none;
  position:absolute;
  bottom: 20px;
  left: 40px;
}
 .banner_title_name p{
  display: block;
  color: #fff;
  margin: 0px;
  padding: 0px;
  font-size: 16px;
  display:inline;
  word-wrap: break-word;
  font-weight: bold;
}
.center_caousel_item:hover .banner_title_name{
  display: block;
}


@-webkit-keyframes circulate {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@-moz-keyframes circulate {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@-o-keyframes circulate {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes circulate {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.mainspinner {
  content: " ";
  width: 120px;
  height: 120px;
  /*background-color: #8ED2C9;*/
  border-radius: 8px;
  position: relative;
  margin: 0 auto;
}

.loading {
  position: absolute;
  top: 25%;
  left: 25%;
  border: 4px solid transparent;
  border-left: none;
  border-top: 5px solid #2CD7F3 !important;
  border-radius: 100%;
  width: 50%;
  height: 50%;
  -webkit-animation: circulate 0.6s infinite linear;
  -moz-animation: circulate 0.6s infinite linear;
  -ms-animation: circulate 0.6s infinite linear;
  -o-animation: circulate 0.6s infinite linear;
  animation: circulate 0.6s infinite linear;
}
#pageloader {
  background: rgba(0, 0, 0, 1);
  height: 100%;
  position: fixed;
  top: 53px;
  left: 0;
  width: 100%;
  z-index: 9999;
}
.loader-item {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  width: 150px;
  height: 150px;
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.footer_details.wrapper_uptandc span{
  color: #ccc;
}
.footer_details.wrapper_uptandc p{
  margin-bottom: 8px;
}
.wrapper_uptandc.footer_details ol li{
  color: #ccc;
  line-height: 1.6;
  margin-bottom: 5px;
}
.wrapper_uptandc.footer_details .tel_quiz_rules{
  width: 100%;
  float: left;
  margin-bottom: 20px;
}
.carousel_body .home_carosel_title .see_more_part{
    position: absolute;
    right: 0;
    top: 0;
}
.carousel_body .home_carosel_title .see_more_part p{
  margin: 6px 10px; 
  font-weight: bold;
  color: #2cd7f3;
  display: inline-block;
}
.carousel_body.nestedCarosul{
	padding: 0 55px;
  overflow: hidden;
  padding-right: 70px;
}
.carousel_body.nestedCarosul .owl-carousel .owl-stage-outer{
  overflow: inherit;
}

