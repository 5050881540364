.complete_registerpopup {
    padding: 20px;
    background-color: #2D2D2D;
    top: 10%;
    border-radius: 16px;
}
  .complete_registerpopup .modal-title , .success_illustration .modal-title p{
       color: #fff;
       text-align: center;
       font-size: 22px;
       padding-bottom: 10px;
  }
  .success_illustration .modal-title p{
    font-weight: 400;
    margin-bottom: 0px !important;
  }
  .complete_registerpopup .modal-header , .success_illustration .modal-header{
    /* border-bottom: transparent !important; */
    position: relative;
    padding: 0px !important;
  }
  .complete_registerpopup .modal-header img , .success_illustration .modal-header img{
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .complete_registerpopup .modal-body .popup_text{
    color:#CCCCCC;
    font-size: 14px;
    margin: 0px;
    text-align: center;
  }
  .complete_registerpopup .modal-body p{
    color: #fff;
    margin-bottom: 24px;
    font-size: 16px;
    /* font-family:"Roboto Medium"; */
    /* text-align: center; */
  }
  .complete_registerpopup .modal-body{
    padding: 0px;
  }
  .complete_registerpopup .modal-body .navbar-form{
    padding: 0px;
  }
  .complete_registerpopup .gender_selection{
    position: relative;
    border: 1px solid #C3C9D5;
    border-radius: 5px;
    display: flex;
    background-color: #383844;
    /* width: 50%; */
    color: #fff;
    margin: auto;
  }
  .complete_registerpopup .gender_selection label{
    padding: 5px 10px;
    margin: 0px;
    width: 35%;
    text-align: center;
    font-weight: 500;    
    cursor: pointer;
  }
  .complete_registerpopup .gender_selection input[type="radio"] {
    display: none;
  }
  .complete_registerpopup .gender_selection input[type="radio"]:checked + label {
    background-color: #2CD7F3;
    border-color:#2CD7F3;
    border-radius: 4px;
  }
  .complete_registerpopup .select_data{
    padding: 10px 0px 50px 0px;
  }
  .complete_registerpopup .submit_btn{
    margin: auto;
    display: block;
    background: none;
    outline: none;
    border: 1px solid #2CD7F3;
    border-radius: 18px;
    color: #fff;
    background-color: #2CD7F3;
    padding: 6px 45px;
    margin-bottom: 20px;
    margin-top: 30px;
  }
  .complete_registerpopup .claim_reward{
    text-align: center;
    color: #999999 !important;
  }
  .complete_registerpopup .years_birth{
    text-align: center;
    display: inline;
    margin-bottom: 30px;
    justify-content: space-between;
  }
  .complete_registerpopup .years_birth label{
    color: #fff;
    font-weight: 500;
    width: 50%;
    text-align: start;
    cursor: pointer;
  }
  .complete_registerpopup .years_birth select{
    border:1px solid #C3C9D5;
    background-color: #383844;
    border-radius: 5px;
    padding: 5px 10px;
    color: #fff;
    width: 129px;
    cursor: pointer;
  }
  .success_illustration{
    display: block;
    margin: auto;
    padding: 20px;
    text-align: center;
  }
  .success_illustration .modal-body img{
    display: block;
    margin: auto;
    margin-bottom: 30px;
  }
  .success_illustration p{
    color:#C3C9D5;
    margin-bottom: 20px;
  }
  .success_illustration h1{
    color: #fff;
    font-size: 20px;
    font-family:"Roboto Medium";
    margin-bottom: 20px;
  }
  .success_illustration button{
    background: #2CD7F3;
    border: none;
    border-radius: 18px;
    padding: 10px 40px;
    color: #fff;
  }
  .popup_content{
    width: 60%;
    margin: auto;
    padding-top: 20px;
  }
  .complete_registerpopup .closeicon, .success_illustration .closeicon{
    cursor:pointer;
  }
  .popupterms{
    color: #fff;
    font-size: 12px !important;
    margin-top: 28px !important;
  }
  @media screen and (min-width:320px) and (max-width:767px) {
    .complete_registerpopup .gender_selection{
        width: 80%;
        margin: inherit;
      }
      .complete_registerpopup .modal-title{
        display: block;
        font-size: 18px;
        text-align: start;
      }
      .popup_content{
        width: 100%;
      }
      .popup_registration{
        top: 15% !important;
      }
  }