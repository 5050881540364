.main-container {
  min-height: 100vh;
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: 120px;
}
.device_section{min-height: 400px;}


.devices_blocks ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
}
.footer_recommendations .drop-down-menu input[type=checkbox] {
  visibility: hidden;
}
footer {
  /* position: absolute; */
  bottom: 0;
  width: 100%;
}
.wrapper-logo img {
  width: 150px;
  margin: 10px 0px;
}
.wrapper-header .navbar-default {
  background-color: unset;
  border: unset;
}
.cast_color{
  color:#999;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
}
.wrapper-header {
  /* padding: 0 15px; */
  /* border-bottom: 3px solid #2CD7F3; */
  background-color: transparent;
}
.wrapper-header .navbar {
  margin: unset;
}
.wrapper-header .nav .dropdown-menu {
  background:#212121;
}
.wrapper-header
  .nav
  .dropdown-menu
  .wrapper-header
  .nav
  .dropdown-menu
  > li
  > a:focus,
.dropdown-menu > li > a:hover {
  background-color: transparent !important;
  color: #666 !important;
}
.home-movie-list .item {
  margin: 0px 6px;
}
.home-movie-list h2.title {
  font-size: 20px;
  text-transform: capitalize;
  top: 0;
  line-height: normal;
  margin-bottom: 20px;
}
.home-movie-list a {
  color: #666;
  text-decoration: none;
}
.home-movie-list a:hover {
  text-decoration: none;
}
.home-banner img {
  width: 100%;
}
.search-box {
  margin-top: 10px;
  margin-right: 70px;
}
.wrapper-header .navbar-nav li a {
  font-family: "Roboto", Arial;
  font-size: 18px;
  color: #999;
  text-decoration: none;
}
.wrapper-header .navbar-nav li .active {
  color: #eb1e27;
}
.wrapper-header .navbar-nav li:hover a {
  color: #eb1e27;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .open > a:hover {
  background-color: unset !important;
}
.wrapper-header ul.wrapper_manu {
  padding: 3px 0px 0px 40px;
}
.wrapper-header .nav .dropdown-menu {
  top: 40px;
  left: -23px;
  border-radius: 4px;
}
.fixed-top {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 999 !important;
}
.home-banner {
  padding-top: 53px;
}
.navbar-nav > li {
  position: relative;
  display: block;
  padding: 10px 24px;
  /* margin: 13px 20px; */
  font-size: 18px;
  color: #999;
  cursor: pointer;
}
.navbar .nav > li > .dropdown-menu:before {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  top: -18px;
  border: 9px solid transparent;
  border-bottom: 12px solid #2e2e2e;
  position: absolute;
  left: 48px;
}
.navbar .nav > li > .dropdown-menu {
  min-width: 135px;
}
.hamburger_line {
  width: 15px;
  height: 3px;
  background-color: #ffffff;
  display: block;
  position: relative;
  transition: all 0.15s ease-in;
}
.hamburger_line::before {
  top: -7px;
}
.hamburger_line::after {
  top: 7px;
}
.hamburger_line::after{
  position: absolute;
  left: 0px;
  height: 3px;
  background-color: #ffffff;
  width: 23px;
  display: block;
  content: "";
  transition: all 0.15s ease-in;
}
.hamburger_line::before {
  position: absolute;
  left: 0;
  height: 3px;
  background-color: #ffffff;
  width: 23px;
  display: block;
  content: "";
  transition: all 0.15s ease-in;
}
/* .hamburger_menu.open .hamburger_line::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(45deg);
  transition: all 0.15s ease-in;
}
.hamburger_menu.open .hamburger_line::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-45deg);
  transition: all 0.15s ease-in;
}
.hamburger_menu.open .hamburger_line,
.hamburger_menu.open .trigger:hover .hamburger_line {
  background-color: transparent !important;
} */
.mobile_toggle {
  position: relative;
  top: 10px;
  left: 25px;
  float: left;
}
.mobile_toggle > ul > li > .dropdown-menu:before {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  top: -23px;
  border: 11px solid transparent;
  border-bottom: 12px solid #212121;
  position: absolute;
  left: 25px;
}
.mobile_toggle .dropdown-menu img{
  left: -12px;
  position: relative;
  width: 18px;
  height: 18px;
}
.mobile_menu .dropdown-menu {
  top: 30px !important;
  left: -42px !important;
}
.mobile_toggle ul .dropdown-menu li {
  text-transform: capitalize;
  padding: 2px 2px;
}
.mobile_toggle ul .dropdown-menu li .submenu-list__item {
  padding: 5px 10px;
}
.hamburger_dropdown {
  float: left;
  margin: 10px 0px;
  cursor: pointer;
}
.hamburger_dropdown .dropdown-menu {
  margin-top: 10px !important;
  left: -20px;
}
.select_hamburger > li {
  margin: 0px;
}

.profile_icon {
  list-style: none;
  position: relative;
  float: right;
  cursor: pointer;
  width: 70px;
  height: 40px;
}
.profile_icon img {
  width: 28px;
  margin: auto;
  display: block;
  float: right;
}

.trigger {
  width: 33px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  z-index: 999;
  /* bottom: 10px; */
  top: 5px;
  /* top: 60px; */
  left: 15px;
}

.profile_icon .trigger {
  width: 45px;
}
#circle {
  width: 30px;
  height: 30px;
  background: #eb1e27;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
}
.profile_icon i {
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  left: 9px;
  position: relative;
  top: 5px;
}
.hamburger_dropdown .mobile_menu {
  top: 15px;
  left: -40px;
}
.dropdown-menu li span:hover {
  color: #fff;
}
.dropdown-menu .active .submenu-list__item {
  color: #ffffff;
}
.search-hint {
  background-color: #323232;
  position: relative;
  width: 260px;
  /* border: 1px solid #666; */
  border-radius: 20px;
  padding: 4px;
  color: #999;
}
.search-hint label {
  margin: 0px;
}
.label-hint {
  position: relative;
  display: block;
}
.label-hint input {
  font-family: "Roboto", Arial;
  background-color: transparent;
  border: none;
  width: 100%;
  padding-right: 30px;
  font-weight: normal;
}
.search-holder {
  display: none;
}
.search-btn {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  /* background-image: url({search.png}); */
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  border: none;
}
input:focus {
  outline: none;
}
.submenu-list__item {
  font-family: "Roboto", Arial;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
  padding: 7px 0px 7px 20px;
  margin-bottom: 2px;
  display: inline-block;
}
.caret-right {
  display: block;
  /*background: url(./images/asset2.svg) center center no-repeat;*/
  margin-left: 5px;
  width: 15px;
  height: 10px;
  transform: rotate(-90deg);
  transition: all 0.15s ease-in;
  position: absolute;
  right: -4px;
  bottom: 18px;
  background-repeat: no-repeat;
}
.navbar.navbar-default .nav.navbar-nav li:hover .caret-right {
  /*    background: url(./images/asset3.svg) center center no-repeat;*/
  transform: rotate(0);
}
.navbar.navbar-default .nav.navbar-nav li.dropdown:hover .dropdown-menu {
  display: block;
}
.profile_icon .dropdown-menu {
  display: none;
  top: 36px;
  left: -140px;
  background: #212121;
  min-width: 222px;
}
.profile_icon .profile_signin{
  top: 38px;
  background: #232323 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px #00000029;
  min-width: 170px;
  position: absolute;
  z-index: 999;
  float: left;
  padding: 0;
  font-size: 14px;
  right: 0;
  border-radius: 8px;
  margin: 2px -9px 0 0;
  cursor: pointer;
  color: #fff;
  display: none;
}
.profile_icon .profile_signin:before {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  top: -23px;
  border: 11px solid transparent;
  border-bottom: 12px solid #212121;
  position: absolute;
  right: 13px;
}
.profile_icon .profile_signin ul{
  margin: 0px;
  padding-left: 10px;
}
.profile_icon .profile_signin ul li{
  padding: 10px 0 10px 18px;
  font-size: 13px;
  }
.profile_icon .dropdown-menu:before {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  top: -24px;
  border: 11px solid transparent;
  border-bottom: 12px solid #212121;
  position: absolute;
  left: 185px;
}
.profile_icon:hover .dropdown-menu{
  display: block !important;
}
.profile_icon:hover .profile_signin{
  display: block !important;
}
/** Sign In **/

.signin_modal {
  background-color: #232325;
}
.signin_modal .modal-header {
  border: none !important;
}
.signin_modal h4 {
  font-family: "Roboto", Arial;
  font-size: 18px;
  text-align: center;
  color: #fff;
}
.signin_btn {
  text-align: center;
}
.signin-part input {
  font-family: "Roboto", Arial;
  width: 60%;
  margin: 0px auto;
  border: none;
  background-color: transparent;
  font-size: 13px;
  color: #fff;
  padding: 10px 6px;
}
.signin-part .close:hover {
  color: #fff;
}
.signin-part .form-control:focus {
  box-shadow: unset;
}
.signin-part input .form-control {
  width: 50%;
}
.signin-part .close {
  color: #fff;
}
.signin_btn p {
  color: #fff;
  margin: 4px 0px;
  margin: 10px 0px;
  font-size: 13px;
}
.signin-part .bar {
  position: relative;
  display: block;
  width: 60%;
  margin: 0px auto;
  border-bottom: 2px solid #757575;
}
.signin-part input:focus {
  outline: none;
  border-bottom: inherit;
}
.signin_btn button {
  width: 61%;
  border: none;
  background-color: #39b54a;
}
.btn-success:hover {
  border: none !important;
  font-weight: normal !important;
  background-color: #398439 !important;
}
.support {
  font-family: "Roboto", Arial;
  text-align: center;
  color: #666;
  font-size: 12px;
}
.error_msg {
  font-family: "Roboto", Arial;
  color: #eb1e27 !important;
  font-weight: 500;
  text-align: center;
  font-size: 12px;
}
.success_msg {
  color: green;
}
.signin-part input:focus ~ .bar:before,
.signin-part input:focus ~ .bar:after {
  width: 50%;
}
.signin-part .bar:after,
.signin-field .active-item .bar:after {
  right: 50%;
}
.signin-part .bar:before,
.signin-part .bar:after,
.signin-field .active-item .bar:before,
.signin-field .active-item .bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: -2px;
  position: absolute;
  background: #a80929;
  transition: 0.5s ease all;
  -moz-transition: 0.5s ease all;
  -webkit-transition: 0.5s ease all;
}
.signin-part .bar:before,
.signin-field .active-item .bar:before {
  left: 50%;
}
/** Search **/

.live-search-result {
  position: absolute;
  width: 320px;
  padding: 0;
  top: 32px;
  left: 0;
  background-color: #000;
  z-index: 99;
  display: block;
}
.search-list {
  padding: 10px;
  width: 100%;
  margin: 0;
  list-style: none;
}
.search-list__item {
  padding-bottom: 10px;
}
.search-list__link {
  display: flex;
}
.search-list__item .image-holder {
  width: 128px;
  /* height: 72px; */
  margin-right: 12px;
}
.search-list__item .image-holder img {
  width: 100%;
  height: 65px;
  object-fit: cover;
  border-radius: 8px;
}
.search-list__item .text-holder {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}
.search-list li .search-list__link {
  font-size: 18px;
  color: #999;
  text-decoration: none;
  /* border: 1px solid #666; */
  padding: 5px;
  border-radius: 3px;
  background-color: #171719;
}
.search-list li .search-list__link:hover {
  cursor: pointer;
  box-shadow: 0px 0px 4px #ffff;
}
.result-name,
.result-name:hover {
  color: #fff;
}
.result-all-btn,
.result-name,
.result-title {
  font-family: "Roboto", Arial;
  font-size: 14px;
}
.result-title {
  color: #999 !important;
}
.result-all-btn {
  background-color: transparent;
  text-align: center;
  color: #fff;
  width: 100%;
  border: none;
  padding: 0px 10px;
}
button.result-all-btn:hover {
  color: #2CD7F3;
}
.flex-dev:hover .episodes-info span {
  color: #eb1e27;
  text-decoration: none;
}
.popover-title {
  display: none;
}
.popover-content h4 {
  margin: 0px;
}
#popover-positioned-top {
  color: #ffff;
  background: #eb1e27;
}
.popover.top > .arrow:after {
  border-top-color: #eb1e27 !important;
}
/**Toast msg**/

.mini-toastr {
  position: fixed;
  z-index: 99999;
  right: 12px;
  top: 50%;
}
.mini-toastr__notification.-info {
  background-color: #eb1e27 !important;
}
.submenu-language {
  font-size: 18px;
  color: #c6c6c6;
  cursor: default;
}
.success-alert {
  text-align: center;
  color: green;
  font-size: 12px;
}
.watch_title {
  position: absolute;
  width: 215px;
  padding: 0;
  bottom: 85px;
  left: 0;
  z-index: 99;
  display: block;
  background: #2CD7F3;
  color: #000;
  border: 2px solid #2CD7F3;
  left: 5px;
  border-radius: 3px;
  padding: 5px;
  font-weight: bold;
}
.results_empty {
  text-align: center;
}
.results_empty h3 {
  font-family: "Roboto", Arial;
  font-size: 24px;
  color: #999;
}
.results_empty h2 {
  font-family: "Roboto", Arial;
  color:#2cd7f3;
  margin: 0px;
  font-weight: 400;
  font-size: 26px;
}
.results_empty p {
  font-family: "Roboto", Arial;
  color: #999;
}
.reset_filter_link {
  width: 100%;
  float: right;
}
.reset_filter_link h4 {
  font-family: "Roboto", Arial;
  cursor: pointer;
  color: #eb1e27;
  font-size: 16px;
  float: right;
}
.inside-page-filters {
  width: 100%;
  float: right;
}
.search_hint_mobile {
  background-color: transparent;
  border: none;
  position: absolute;
  right: 15px;
  top: -5px;
}
.search_hint_mobile img {
  width: 22px;
  position: relative;
  top: 5px;
}

.download-link-group {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.download-link-group a {
  margin: 0;
}
.support {
  color: #ffffff;
}
.signin_back .previous_screen {
  color: #fff;
  cursor: pointer;
  display: inline-block;
}
.signin_back .previous_screen i {
  margin-right: 5px;
}
.signin_back:hover {
  color: #eb1e27;
  text-decoration: none;
}
.previous_screen:hover {
  color: #eb1e27;
  text-decoration: none;
}
.broadband .signin_youbroadband {
  background-color: #1976bc;
  border-color: #1976bc;
}
.broadband .signin_youbroadband:hover {
  background-color: #286090;
  border-color: #204d74;
}
.signin_youbroadbnd {
  width: 61%;
  border: none;
  padding: 6px 0px;
  border-radius: 30px;
  margin-bottom: 5px;
}
.btn-primary.focus,
.btn-primary:focus {
  outline: none;
}
.broadbnd {
  text-align: center;
}
.close_icon img {
  width: 17px;
  height: 18px;
}
.mobile_toggle ul li i {
  float: left;
}
.mobile_toggle .dropdown-menu {
  display: none;
}
.mobile_toggle.openmenu .dropdown-menu {
  display: block;
}
.you_broadband {
  text-align: center;
  display: none;
}
.signin_youbroadband {
  width: 61%;
  background-color: #39b54a;
  margin-bottom: 15px;
  border-radius: unset;
  border: none;
  padding: 8px;
  margin: 0px auto;
  font-family: "Helvetica-Regular", sans-serif;
  background-color: #1976bc;
  border-radius: 30px;
  margin-bottom: 10px;
}
.signin_queries {
  font-size: 14px;
  font-family: "Helvetica-Regular", sans-serif;
  color: #666;
}
.youbroadband_logo {
  text-align: center;
  margin-bottom: 5px;
}
.switch_light {
  margin-left: 15px;
}
.btn-primary.dark:hover {
  background-position: 61px -30px;
}
.switch_light .btn.light {
  background-position: 0px -60px;
  background-color: transparent;
  border: none;
  text-indent: -9999px;
  width: 61px;
  height: 30px;
  min-width: 60px;
  margin: 0px;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.switch_light .btn.dark {
  background-position: 61px 30px;
  background-color: transparent;
  border: none;
  text-indent: -9999px;
  width: 61px;
  height: 30px;
  min-width: 60px;
  margin: 0px;
}
.light-theme .switch_light .btn.light {
  background-position: 0px 0px;
}
.light-theme .switch_light .btn.dark {
  background-position: 61px 0px;
}
.dark-theme .switch_light .btn.dark {
  pointer-events: none;
}
.switch_light .btn.light:hover {
  background-position: 0px -90px;
}
.light-theme .switch_light .btn.light:hover {
  background-position: 0px 0px;
  cursor: default;
}
.light-theme .switch_light .btn.dark:hover {
  background-position: 61px -30px;
}
.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.switch_light .btn-group {
  position: absolute;
  right: 55px;
}
body.dark-theme {
  /* background-color: #171719 !important; */
  background-color: #000000 !important;
}
body.light-theme {
  background-color: #0F1010 !important;
}
.light-theme .wrapper-header {
  background-color: #ffffff !important;
}
.light-theme .footer {
  background-color: #dcdcdc;
}
.home_movie_list_wrap {
  width: 100%;
}
.error_section_wrap {
  margin: 0 auto;
  text-align: center;
}
.error_section_container {
  padding: 13% 0 !important;
}
.error_message_container h2 {
  font-weight: 900;
  font-size: 36px;
  color: #666;
}
.error_message_container p {
  font-size: 18px;
  color: #4b4b4b;
  min-height: 50px;
  margin: 0;
}
.flowplayer .fp-header .fp-share {
  display: none;
}
.lead-more-btn:hover {
  background: #eb1e27;
}
.lead-more-btn {
  font-family: "Roboto", Arial;
  font-weight: 500;
  background: #2e2e2e;
  padding: 15px 25px;
  font-size: 14px;
  border-radius: 25px;
  color: #fff;
  min-width: 140px;
  outline: 0 !important;
  box-shadow: none !important;
  margin: 35px auto;
  display: block;
  border: none;
  transition: all 0.15s ease-in;
}
.video-socials-list li a {
  color: #000;
}
.info_msg {
  background-color: #232325;
}
.info_title {
  color: #ccc;
  font-size: 13px;
  text-align: center;
  padding-top: 15px;
}
.adblockerTitle{
  font-size: 18px !important;
}
.error-message {
  background-color: #000;
  color: #fff;
  font-weight: bold;
}
.modal_infomsg .modal-dialog {
  margin: 53px auto;
}
.footer_details_title h2 {
  padding: 0px !important;
}
.installapp {
  width: 100%;
  /* display: none; */
}
.install_row {
  border-bottom: 0px solid #0F1010;
}
.installapp h5 {
  font-family: "Roboto", Arial;
  font-weight: 100;
  color: #fff;
  margin: 0px;
  font-size: 12px;
}
.btn-install {
  font-family: "Roboto", Arial;
  float: right;
  color: #fff;
  border: 1px solid #29C1DF;
  border-radius: 3px;
  font-size: 11px;
  top: 10px;
  position: relative;
  padding: 2px 2px;
  background-color: transparent;
}
.install_row div {
  padding: 0px;
}
.install_row {
  padding: 5px 25px;
}
.installapp button a {
  font-size: 11px;
  color: #29C1DF;
  text-decoration: none;
  cursor: pointer;
}
.installapp h6 {
  font-family: "Roboto", Arial;
  color: #999;
  margin: 0px;
  font-size: 10px;
}
.topheader_logo {
  float: left;
  padding-top: 11px !important;
  margin-right: 10px;
}
.installapp .install_row .topheader_data {
  padding-left: 40px;
}
.topheader_logo img {
  width: 35px;
}
.topheader_mnr_logo{
  float: left;
  margin-right: 7px;
  padding-top: 5px !important;
}
.wrapper-header .nav .dropdown-menu {
  min-width: 180px;
}
.addto_home_btn {
  display: none;
}
.fa-plus-square {
  color: #ccc;
  font-size: 22px !important;
}
.fa-arrow-left:before {
  color: #ccc;
}
.previous_screen {
  float: left;
}
.previous_screen .fa-arrow-left {
  padding: 3px 10px 3px 0px;
}
.synopsis_content {
  color: #999;
  font-size: 14px;
  margin-bottom: 4px;
}
.synopsis_content a {
  cursor: pointer;
  color: #eb1e27 !important;
  display: block;
  text-decoration: none !important;
}
.synopsis_content h5 {
  color:#2CD7F3;
  cursor: pointer;
  float: left;
  margin: 0px;
}
.recent_search,
.recent_search_results {
  width: 100%;
  float: left;
}
.recent_search_title {
  float: left;
}
.recent_search_clearall {
  float: right;
}
.trending_search h2 {
  font-family: "Roboto", Arial;
  font-size: 14px;
  color: #ccc;
  margin: 5px 0px 5px 0px;
}
.recent_search_clearall h2 {
  color: #eb1e27;
  font-size: 13px;
  cursor: pointer;
}
.recent_search_results ul {
  padding: 0px;
}
.recent_search_results ul li {
  color: #ccc;
  padding: 6px 0px;
  font-size: 12px;
}
.trending_search {
  width: 100%;
  height: 100%;
  float: left;
}
.profile_icon .watchlist-icon img,
.profile_icon .logout-icon img {
  width: 18px;
  float: left;
}
.label-hint input,
.result_area p {
  font-size: 14px;
}
.signin_back {
  color: #fff;
  top: 6px;
  position: absolute;
  cursor: pointer;
}
.signin_back i {
  margin-right: 5px;
}
.media-body.live-search-text {
  font-size: 12px;
}
.shake_addtohome {
  width: 26px;
}
.actionbar_back {
  width: 32px;
  position: relative;
  padding: 7px 5px;
}
.support_vfmail {
  color: #eb1e27 !important;
  text-decoration: underline;
}
.support_ideamail {
  color: #502e88 !important;
}
.backto_previous_screen .actionbar_about {
  float: left;
  width: 39px;
  position: relative;
  bottom: 6px;
  padding: 2px 10px 1px 0px;
  cursor: pointer;
}
.flowplayer .fp-logo {
  position: absolute;
  bottom: 40% !important;
  left: 10px !important;
}
.flowplayer .fp-logo img {
  width: 50% !important;
}
.flowplayer .fp-message.fp-shown{display: none !important;}
.flowplayer .fp-buffer, .flowplayer .fp-progress {
  cursor: default;
}
.flowplayer .fp-bar, .flowplayer .fp-bar-slider{cursor: default !important;}
.player_coverposter img{width: 100%;}
.info_header{
  color: #fff;
  font-size: 18px;
  text-align: center;
  border-bottom: 1px solid #666 !important;
  padding-bottom: 6px;
}
.modal-header {
  padding: 15px 0px 15px 0px !important;
  border-bottom: 1px solid #666 !important
}
.modal-content {
  background-color: #171719 !important;
}
.modal-header button{padding-right: 20px !important; color:#fff !important;}
.player_coverposter{position: relative;}
.play_logo{
  width: 100%;
  position: absolute;
  bottom: 40px;
  left: 30px;
  cursor: pointer;
}
.play_logo img{
  width: 30px;
  bottom: 5px;
  cursor: pointer;
  float: left;
  margin: 5px;
}
.play_logo div, .play_logo ul li{ 
  color:#fff !important; 
  font-size: 14px !important;
  margin-top: 10px;
}
.flowplayer .fp-controls img{width: 50% !important;}

/*rating css*/
#star-rating {
  float: left;
  margin: 0px;
  padding: 0px;
  color: #fff;
}

#star-rating li {
  padding-right: 0px;
  display: inline;
  color: transparent;
}

#star-rating li:after {
  content: "\2605";
  color: #fff;
  font-size: 1.2em;
}

#star-rating li.rated:after {
  content: "\2605";
  color: #2CD7F3;
  font-size: 1.2em;
}
.app_rating {
  position: relative;
  /* width: 116px; */
  display: initial;
}
.app_rating{
  width: 100%;
  /*float: left;*/
  }
.app_rating p {
    color: #999;
    position: relative;
    top: 4px;
    font-size: 13px;
    float: left;
    margin: 0px;
}
.flow_player{
  min-height: 500px;
}
.breadcrumb_section{    
  width: 70%;
  margin: 6px 0px;
  float: left;
  padding-left: 14px;
}
.breadcrumb_section ul{
  list-style: none;
    width: 100%;
    color: #3e3e3e;
    margin: 0;
}
.breadcrumb_section ul li{
  font-size: 15px;
  color: #999;
  display: inline-block;
  text-transform: capitalize;
}
.breadcrumb_section ul li + li:before {
  content: "|";
  padding: 0 5px;
}
.breadcrumb_section ul li a{color:#999;
  text-decoration: none;
}
.breadcrumb_section ul li a:hover{color:#eb1e27;}
.installapp .install_row .topheader_data {
  padding: 8px 0px 0px 5px;
}
.topheader_bgimg{
  /* background: url(../../assets/images/install_app_strip.png) no-repeat scroll 0 0; */
  background-position: left bottom;
  background-size: cover;
  height: 44px;
  width: 400px;
}
.topheader_imt_bgimg{
  background: url(../../assets/images/IMT_install_app_strip.png) no-repeat scroll 0 0;
  background-position: left bottom;
  background-size: cover;
  height: 44px;
  width: 400px;
}


.installapp h6 {
  font-family: "Roboto", Arial;
  color: #999;
  margin: 0px;
  font-size: 10px;
  color: #29C1DF;
  font-size: 12px;
  padding-top: 4px;
}
/* .topheader_logo{display: none;} */
.topheader_logo img{
  width: 16px;
}
.redirect_section img{width: 30%;}
.redirect_section.footer_details {margin-bottom: 0px;}

.idea-theme-desktop.wrapper_desktop .desktop_header {
  background-color: #9b5aa4;
}
.idea-theme-desktop.wrapper_desktop {
  background-color: #e8e8e8;
}
.idea-theme-desktop.wrapper_desktop .desktop_header img {
  width: 160px;
}
.idea-theme-desktop.wrapper_desktop .desktop_body h1 {
  color: #502e88;
}
.idea-theme-desktop.wrapper_desktop .desktop_body p {
  color: #444;
}
.idea-theme-desktop.wrapper_desktop .desktop_footer {
  background-color: #fff;
}
.vodafone-theme .desktop_header {
  background-color: #eb1e27;
}
.vodafone-theme .wrapper_desktop .desktop_header {
  background-color: #eb1e27;
}
.vodafone-theme.wrapper_desktop {
  background-color: #222222;
}
.vodafone-theme.wrapper_desktop .desktop_header img {
  width: 100px;
}
.vodafone-theme-desktop.wrapper_desktop .desktop_body h1 {
  color: #595a5a;
}
.vodafone-theme-desktop.wrapper_desktop .desktop_body p {
  color: #444;
}
.vodafone-theme-desktop.wrapper_desktop .desktop_footer {
  background-color: #171719;
}
.flowplayer .fp-backward.is-disabled,
.flowplayer .fp-forward.is-disabled {
  color: #999 !important;
}
.promo_video{
  width: 100%;
  float: left;
  margin-bottom: 8px;
}
.promo_video_coverposter{width: 100%;}
.promo_video h1{
  color: #999;
  font-size: 16px;
  margin: 0px 0px 2px 5px;
}
.promoStaticBanner{
  width: 100%;
  float: left;
  margin: 8px 0px;
  padding: 0 15px;
}
.promoStaticBanner img{
  border-radius: 8px;
  cursor: pointer;
}
.promoStaticBanner h1{
  color:#ffffff;
  font-size: 18px;
  margin-top: 0px;
  padding-left: 40px;
  font-family: "Roboto", Arial;
}
.promo_video .flowplayer .fp-engine{ border-radius: 8px 8px 0px 0px;}
.singleBanner {
  width: 100%;
  float: left;
  position: relative;
  margin: 20px 0px;
}
.single_banner_logo {
  position: absolute; top: 0; right: 10px;
}
.onscroll_modal img{width: 100%;}
.watchnow_title{width: 100%;
  position: relative;
  bottom: 15px;}
.watchnow_modal_section.modal{top: 20%;}  
.watchnow_btn{text-align: center;}
.watchnow_btn button{background-color:#eb1e27; border: none;}
.watchnow_title .wondering{font-size: 16px; font-weight: 500;}
.watchnow_title .recommend{font-size: 14px;color: #999; font-weight: 500;}
.watchnow_modal .modal-header .close{ 
  color: #fff;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  border: 2px solid #fff;
  padding: 0px !important;
  margin-right: 9px;
  opacity: inherit;
  text-shadow: none;
}



/* language selection */
.language_section{position: relative; float: right; margin-right: 30px; }
.language_title{
  width: 45%;
  float: left;
  margin: 0px;
} 
.language_section h5{
   color: #fff;
   cursor: pointer;
 }

.language-dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0px;
  z-index: 1000;
  /* display: none; */
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0,0,0,.175);
  background-color: #171719;
}

.lang_btn{
  color: #fff;
  background-color:#9f181b;
  text-align: center;
}

.lang_btn button{
  background-color: transparent;
  border: none;
  border-right: 1px solid #999;
  padding: 9px 18px;
  font-weight: bold;
  width: 50%;
}
.btn_save{
  border-right: 0px !important;
}
.language_selection input[type='checkbox'] + label{margin: 0px;}
.language_selection{
      width: 100%;
      padding: 8px 5px;
      border-top: 1px solid #333;
     }

.language-dropdown-menu:before {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  top: -18px;
  border: 9px solid transparent;
  border-bottom: 12px solid #2e2e2e;
  position: absolute;
  left: 120px;
}


.language_selection input[type='checkbox']{ height: 0; width: 0; }
     
.language_selection input[type='checkbox'] + label{
     font-family: "Roboto", Arial;
      font-weight: 500;
       position: relative;
       /* display: flex; */
       margin: .6em 0;
       align-items: center;
       color: #9e9e9e;
       transition: color 250ms cubic-bezier(.4,.0,.23,1);
       width: 100%;
       cursor: pointer;
     }
.language_selection input[type='checkbox'] + label > ins{
       position: absolute;
       display: block;
       bottom: 0;
       left: 2em;
       height: 0;
       width: 100%;
       overflow: hidden;
       text-decoration: none;
       transition: height 300ms cubic-bezier(.4,.0,.23,1);
     }
.language_selection input[type='checkbox'] + label > ins > i{
       position: absolute;
       bottom: 0;
       font-style: normal;
       color: #4FC3F7;
     }
.language_selection input[type='checkbox'] + label > span{
       display: flex;
       justify-content: center;
       align-items: center;
       margin-right: 1em;
       width: 21px;
       height: 21px;
       background: transparent;
       border: 2px solid #9E9E9E;
       border-radius: 10px;
       cursor: pointer;  
       transition: all 250ms cubic-bezier(.4,.0,.23,1);
       float: right;
     }
     
.language_selection input[type='checkbox'] + label:hover, input[type='checkbox']:focus + label{
       color:#fff;
     }
.language_selection input[type='checkbox'] + label:hover > span, input[type='checkbox']:focus + label > span{
       background: rgba(255,255,255,.1);
     }
.language_selection input[type='checkbox']:checked + label > ins{ height: 100%; }
     
.language_selection input[type='checkbox']:checked + label > span{
       border: .7em solid #eb1e27;
       animation: shrink-bounce 200ms cubic-bezier(.4,.0,.23,1);
     }
.language_selection input[type='checkbox']:checked + label > span:before{
  content: "";
  position: absolute;
  top: 12px;
  /* left: 5px; */
  right: 25px;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-animation: checkbox-check 125ms 250ms cubic-bezier(.4,.0,.23,1) forwards;
  animation: checkbox-check 125ms 250ms cubic-bezier(.4,.0,.23,1) forwards;
  font-size: 24px;
  filter: invert(1);
}
.select_content_language{
  width: 100%;
  float: left;
  padding: 8px;
  background-color:#100f14;
}
.select_content_language h1{
  font-family: "Roboto", Arial;
  font-weight: 500;
  font-size: 18px;
  color: #ccc;
}
.select_content_language label{width: 100%; color:#fff;}
.language_checkmark{
  float: left;
  padding: 0px 15px 0px 15px;}
.language_checkmark:after {
  content: '';
  display: block;
  width: 10px;
  height: 18px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.select_language_section ul{
  padding: 0px;
}
.select_language_section ul li{
  /* background-color:#696969; */
  width: 48%;
  border-radius: 20px;
  padding: 4px 0px;
  margin-bottom: 10px;
  display: inline-block;
  margin: 0px 0px 10px 6px;
}
.language_selected{
   background-color:#696969;
}
.select_language_btn{
  width: 100%;
  float: left;
}
.select_language_btn button{
  background-color: #696969;
  float: right;
  border: none;
  color: #fff;
  padding: 8px 40px;
  border-radius: 5px;
}
.select_language input{
  display: none;
}
.language_text{
  font-size: 14px;
  color: #999;
}
.language_activated{background-color:#eb1e27;}



.drop-down-menu {
    display: block;
}   
.menu-title {
  font-family: "Roboto", Arial;
  font-weight: 400;
  cursor: pointer;
  display: block;
  padding: 8px 10px;
  color:#fff;
  margin: 0px;
  border-bottom: 1px solid #333;
  color: #999;
}
input[type=checkbox] {
  height: 0;
  width: 0;
}
.activate {
  position: absolute;
  cursor: pointer;
}
.activate:checked~.drop-down {
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  /* max-height: fit-content; */
}
.activate:checked~.main .down-arrow:after{
  left: -1px;
}
.drop-down, .drop-down-menu, .menu a {
  -webkit-transform: translateZ(0);
  -webkit-transition: all .3s;
  transition: all .3s;
}
.drop-down {
  max-height: 0;
  overflow: hidden;
}
.devices_blocks {
  width: 100%;
  float: left;
  color:#fff;
  padding: 1% 3%;
  font-family: "Roboto", Arial;
  font-weight: normal;
}
.devices_blocks ul{
  padding: 0px;
  margin: 0px;
  list-style: none;
}   
.devices_blocks ul li {
  line-height: 30px;
  cursor: pointer;
  font-weight: 400;
  font-size: 13px;
}
.devices_blocks ul li a{
  color:#fff;
}
.videojs_player_section{
  position: relative;
}
.videojs_player_section .video-js {
  width: 100% !important;
  /* height: auto !important; */
}

/*down arrow */
.down-arrow:before,.down-arrow:after {
  content:'';
  position:absolute;
  top:26px;
  width:17px;
  border:1px solid #999;
  /* transition:all 0.3s;
  -webkit-transition:all 0.3s; */
}

.down-arrow:before {transform:rotateZ(45deg);-webkit-transform:rotateZ(45deg);left:10px;}
.down-arrow:after {transform:rotateZ(-45deg);-webkit-transform:rotateZ(-45deg);left:21px;}
.down-arrow{
  position: absolute;
  right: 40px;
  top: -8px;
}

/* .video-js.vjs-playing .vjs-tech {
  pointer-events: none;
} */

/* App Language*/
.radio {
  margin: 0.5rem;
}
.radio input[type="radio"] {
  position: absolute;
  opacity: 0;
}
.radio input[type="radio"] + .radio-label:before {
  content: '';
  /* background: #f4f4f4; */
  border-radius: 100%;
  border: 1px solid #ccc;
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  position: relative;
  top: -0.2em;
  margin-right: 1em;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
  float: right;
}
.radio input[type="radio"]:checked + .radio-label:before {
  background-color: #eb1e27;
  box-shadow: inset 0 0 0 4px #000;
  border: 1px solid #eb1e27 !important;
}
.radio input[type="radio"]:focus + .radio-label:before {
  outline: none;
  border-color: #3197ee;
}
.radio input[type="radio"]:disabled + .radio-label:before {
  box-shadow: inset 0 0 0 4px #f4f4f4;
  border-color: #b4b4b4;
  background: #b4b4b4;
}
.radio input[type="radio"] + .radio-label:empty:before {
  margin-right: 0;
}
.checkbox label, .radio label{width: 100%;}
.applanguage_title{
  color:#ccc;
  font-family: "Roboto", Arial;
}
.synopsis_content.show_morelist p{
  width: 100%;
  float: left;
  height: 60px;
  overflow: hidden;
  text-align: left;
}
.synopsis_content.show_lesslist p{
  width: 100%;
  float: left;
  height: auto;
  overflow: hidden;
  text-align: left;
}
.browsers_section{
  width: 100%; 
  text-align: center;
  margin: 10px 0px;
}
.browsers_section img{width: 70px; margin: 0px auto;}
.browsers_footer img{width: 120px;}
.browser_title{
  margin: 0px;
  color: #ccc;
  text-align: center;
  font-size: 15px;
}
.browsers_section .browser_item{
  display: inline-grid;
  text-decoration: none !important;
  margin: 0px 20px;
  color:#ccc !important;
}
.signin_modal .modal-body {
  padding: 0px 15px 15px 15px !important;
}
.offers_btn button{background-color: #9b5aa4 !important;
  border-color:#9b5aa4 !important;}
/* .sony_livebanner{
  background: url(https://micon.vodafoneplay.in/sony_landing.jpg) no-repeat scroll 0 0;
  background-size: cover;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
} */
.sony_imt_livebanner{
  background: url(https://micon.vodafoneplay.in/eng_movies_bg_imt.jpg) no-repeat scroll 0 0;
  background-size: cover;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
}
.sony_btn_section{margin-top: 40px;}
.sony_btn_section button{
  display: none;
}
.browser_footerimg img{width: 120px;}
.add_modal img{width: 100%;}
.add_modal.modal-body{background-color:#0E0E0E;}
.add_modal_header.modal-header{
  background-color:#0E0E0E;
  border: none !important;
}
.add_modal_header.modal-header .close{font-size: 25px;}
.select_add_modal{
  text-align: center;
  padding: 6px;
}
.add_modal_btn{
  background-color:#eb1e27;
  color:#fff;
  border: none;
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 14px;
}
.add_modal.modal-body p{
  font-size: 12px;
  color:#ccc;
  margin: 0px;
  padding: 13px 5px;
}
.add_modal_header h5{
  color: #ccc;
  text-align: center;
}
.terms_center{ 
  text-align: center;
  margin-bottom: 20px;
}

/*shreyas Login/Registration*/
.login_section {
  width: 100%;
  float: left;
  text-align: center;  
}
.login_section .react-datepicker-wrapper{
  display: block;
}
.login_section input{
  border-radius: 20px;
  margin-bottom: 10px;
  width: 100%;
  padding: 7px 15px;
  color: #666;
  border: 1px solid #fff;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
  width: 2.6em !important;
}
.react-datepicker{
  font-size: 14px !important;
}
.login_section .form-control{
  padding: 16px;
}
.login_btn button{
  border-radius: 20px;
  border: none;
  background-color: #FFCC33;
  color: #000;
  padding: 7px 0px;
  width: 70%;
  font-size: 14px;
  font-weight: bold;
}
.login_Phone_btn button{
  border-radius: 20px;
  color:#fff;
  padding: 7px 0px;
  width: 70%;
  background-color: transparent;
  border: 2px solid #FFCC33;
  font-size: 14px;
}
.forgot_pwd ul{
  padding: 0px;
  display: inline-block;
}
.forgot_pwd ul li{
  display: inline-block;
  padding: 0px 4px;
  font-size: 16px !important;
}
.forgot_pwd .click_here{
  color: #2CD7F3;
}
.login_section{
  /* color:#fff; */
  /* font-size: 28px; */
}
.login_section h4{
  color: #ccc;
  font-size: 24px !important;}
.login_section h4{
  color: #fff;
  }  
.login_section select{
  width: 100%;
  color:#666;
  font-size: 15px;
  padding: 7px;
  border-radius: 20px;
  font-size: 14px;
  background-color: #fff;
}
.login_section select:focus{
  outline: none;
}
.login_section{
  background: url(../../assets/images/login_bg_apex.jpg) no-repeat scroll 0 0;
  background-size: cover;
  background-repeat: no-repeat;
}
.login_mobile_number select{
  width: 20%;
  float: left;
  margin: 0px 8px 0px 0px;
}
.login_mobile_number .login_mobile_field{
  width: 74%;
}
.player_section .youtube-container{
  padding-top: 0px;
}
.actor_profile_section{
  width: 100%;
  text-align: center;
}
.actor_profile_section img{
  margin-bottom: 15px;
  width: 100%;
}
.actor_profile_details h5{
  color:#ccc;
  margin: 0px;
  padding: 0px 0px 2px 0px;
  font-size: 16px;
  text-align: left;
  margin-bottom: 6px;

}
.actor_profile_details p{
  text-align: left;
  font-size: 14px !important;
}
.profile_section h4{
   color:#fff !important;
}
.profile_section h5{
  color:#ccc;
}
.profile_section h1{
  float: left;
}
.profile_section .edit_option{
  float: right;
  color: #666 !important;
}

.actor_profile_section h4{
  text-align: left;
}

.login_section .field_title{
  float: left;
  color:#ccc;
  font-size: 16px;
  font-weight: 500;
}
.content_playbtn{
  width: 100%;
  float: left;
}
.content_playbtn button{
  width: 95%;
}
.profile_details{
    width: 100%;
    float: left;
    text-align: center;
}
.share-video-block{
  width: 295px !important;
}
.actor_profile h4{text-transform: uppercase;}
.profile_details img{
  border-radius: 50%;
}
.profile_default_icon{
  border: 1px solid #ccc;
}
.profile_details .edit_icon{
  width: 30px;
  position: absolute;
}
.footer_details.actor_profile{
  padding: 0px;
  margin-top: 60px;
}
.profile_container{
  width: 95%;
}
.actorprofile_body{
  padding: 0 55px;
  float: left;
  width: 100%;
}
.promotions_section{
  width: 100%;
  float: left;
}
.promotions_section .footer {
  display: none;
}
.promotions_section img{
  width: 100%;
  float: left;
  margin-bottom: 30px;
}
.sliderbody.promotions_section{
  margin-top: 38px;
}
.desktop_app_version h3{
  margin: 4px 0px;
  font-size: 13px;
  color: #999;
}
.desktop_app_version p{
  color: #999;
  font-size: 12px;
  margin: 0px;
}
.previewCarousel {
  border-radius: 50%;
}
.survey_title{
  font-weight: bold;
  color:#999;
  padding-left: 30px;
}
.login_section_title{
  padding-top: 15px;
}
/* .vjs-theme-forest.vjs-big-play-button:focus, .vjs-theme-forest:hover .vjs-big-play-button{
  background: url(https://chnimg.vodafoneplay.in/test/play_icon.png) no-repeat scroll 0 0 !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.vjs-theme-forest .vjs-play-control .vjs-icon-placeholder:before{
  background-color: transparent !important;
  color: #fff !important;
  font-size: 22px;
  margin-top: 0px !important;
}
.vjs-theme-forest .vjs-big-play-button{
  background: url(https://chnimg.vodafoneplay.in/test/play_icon.png) no-repeat scroll 0 0 !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.vjs-menu li.vjs-menu-item:focus, .vjs-menu li.vjs-menu-item:hover{
  outline: none;
}
.vjs-theme-forest .vjs-control:not(.vjs-disabled):not(.vjs-time-control):hover{
  color: #fff !important;
} */
.sigin_head h1{
  font-size: 24px !important;
  margin-right: 30px;
}
.movie_details_logo img{border-radius: 4px;}
.signin_logo{
  width: 50px;     
  margin: 0px auto;
}
.signin_logo img{
  width: 50px;     
}
.login_Phone_btn img{
  width: 40px;
  margin-right: 20px;
}
.signup_mobile {
  width: 77% !important;
}
#countrycodenumber{
  width: 20%;
  float: left; 
  margin-right: 10px;
}
.banner_freetag, .carousel_freetag{
  position: absolute;
  z-index: 9;
  margin-top: 4px;
}
.banner_freetag img{
  width: 40px !important;
}
.carousel_freetag img{
  width: 32px !important;
}
.watermark_img img{
  width: 32px !important;
  
}
/* .vjs-seek-button.skip-forward{
  top: 5px;
}
.vjs-seek-button.skip-back{
   top: 5px;
}
.vjs-menu-button.vjs-icon-cog:before {
    content: "\F110";
    margin-left: 12px;
    font-size: 13px;
} */
.live-search-result .search-list li img{
   height: 65px !important;
}
.search-list__item .text-holder span{
  font-size: 11px;
}
.videojs_player_section div.vast-skip-button{
  cursor: pointer;
  right: 10px;
  background-color:#4c4c4c;
  border: none;
  border-radius: 3px;
  padding: 6px 15px;
}
.videojs_player_section p.vast-skip-button-text{
  font-size: 12px;
}
.device_section h1{
  color: #999;
  font-size: 20px;
}
.device_section p{
  color: #999;
}
.device_section table {
  color: #999;
}
.device_section button{
  border-radius: 20px;
  border: none !important;
  background-color:#FFCC33 !important;
  color:#000;
  padding: 7px 0px !important;
  width: 70%;
  font-size: 13px;
  font-weight: bold;
}
.device_section{
  margin-top: 55px;
}
.device_section button:hover, .device_section button:active{
 font-size: 13px !important;
}
.device_activebtn{
  width: 30% !important;
}
/*-----M-----*/

.wrapper-header:before {
  content: '';
  position: fixed;
  top: -10px;
  left: 0;
  width: 100%;
  height: 10px;
  box-shadow: 0px 0px 41px 35px rgb(0 0 0 / 80%);
  z-index: 0;
}




.nav-mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #446CB3;
  color: #FFF;
  padding: 0;
  margin: 0;
  cursor: auto;
  font-size: 18px;
  list-style-type: none;
  z-index: 999999;
  box-shadow: 0 5px 5px -5px #333;
}
.nav-mobile:after {
  content: "";
  display: table;
  clear: both;
}
.nav-mobile svg {
  height: 45px;
  width: 65px;
  padding: 9px;
}
.nav-mobile svg path {
  fill: #fff;
}
.nav-mobile svg.icon-close {
  display: none;
  padding: 15px;
}
.nav-mobile li {
  width: 100%;
  /* height: 45px;
  line-height: 46px; */
  text-align: center;
  float: left;
}
.nav-mobile li a {
  display: block;
  color: #333;
  width: 100%;
  height: 100%;
  text-decoration: none;
}
.nav-mobile .menu-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;
  display: block;
}
.nav-mobile .menu-button:after {
  opacity: 0;
  top: 0px;
  content: "";
  width: 100vw;
  display: block;
  position: fixed;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  content: "";
  z-index: 9;
  pointer-events: none;
  transition: opacity 0.2s cubic-bezier(0, 0, 0.3, 1);
  transition-delay: 0.1s;
}
.nav-mobile #menu-toggle {
  display: none;
}
.nav-mobile #menu-toggle.active ~ .menu-button .icon-close, .nav-mobile #menu-toggle:checked ~ .menu-button .icon-close {
  display: block;
}
.nav-mobile #menu-toggle.active ~ .menu-button .icon-open, .nav-mobile #menu-toggle:checked ~ .menu-button .icon-open {
  display: none;
}
.nav-mobile #menu-toggle.active ~ .menu-button:after, .nav-mobile #menu-toggle:checked ~ .menu-button:after {
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.3s cubic-bezier(0, 0, 0.3, 1);
}
.nav-mobile #menu-toggle.active ~ .menu-sidebar, .nav-mobile #menu-toggle:checked ~ .menu-sidebar {
  transform: translateX(0);
  transition: transform 0.3s cubic-bezier(0, 0, 0.3, 1);
}
.nav-mobile .menu-container {
  width: 65px;
  float: left;
  cursor: pointer;
  position: absolute;
}
.nav-mobile .menu-container .menu-sidebar {
  box-shadow: 5px 0 5px -5px #333;
  display: block;
  width: 80vw;
  bottom: 0;
  background: rgb(0 0 0 / 90%);
  color: #333;
  position: fixed;
  z-index: 9999999;
  transform: translateX(-405px);
  transition: transform 0.3s cubic-bezier(0, 0, 0.3, 1);
  top: 0px;
  list-style-type: none;
  padding: 0;
  max-width: 400px;
}
.nav-mobile .menu-container .menu-sidebar .arrow {
  position: absolute;
  line-height: 50px;
  font-size: 32px;
  color: #555;
  top: 0;
  z-index: 0;
}
.nav-mobile .menu-container .menu-sidebar .arrow.left {
  left: 25px;
}
.nav-mobile .menu-container .menu-sidebar .arrow.right {
  right: 25px;
}
.nav-mobile .menu-container .menu-sidebar li {
  /* height: 55px;
  line-height: 55px; */
  font-size: 16px;
  text-align: left;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 20px;
}
.nav-mobile .menu-container .menu-sidebar li:hover {
  background: transparent;
}
.nav-mobile .menu-container .menu-sidebar li .menu-sub {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 0;
  overflow: hidden;
  background: white;
  visibility: hidden;
  transition: all 0.3s cubic-bezier(0, 0, 0.3, 1);
  border-left: 1px solid #ccc;
  list-style-type: none;
  padding: 0;
  margin: 0;
  z-index: 2;
  max-width: 400px;
}
.nav-mobile .menu-container .menu-sidebar li .menu-sub li {
  overflow: hidden;
}
.nav-mobile .menu-container .menu-sidebar li .menu-sub .menu-sub-title {
  padding-left: 50px;
}
.nav-mobile .menu-container .menu-sidebar li .submenu-label {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: block;
}
.nav-mobile .menu-container .menu-sidebar li .submenu-toggle {
  display: none;
}
.nav-mobile .menu-container .menu-sidebar li .submenu-toggle.active ~ .menu-sub, .nav-mobile .menu-container .menu-sidebar li .submenu-toggle:checked ~ .menu-sub {
  width: 65vw;
  visibility: visible;
  z-index: 1;
  transition: width 0.35s cubic-bezier(0, 0, 0.3, 1);
}
.sidemenu_close_part{
  float: left;
}
.right_content .profile_icon .dropdown-menu li a {padding: 0px;}
.sidemenu_logo_part{
  float: right;
}
.menu-sidebar .sidemenu_header_part, .menu-sidebar .sidemenu_myaccount_part{
  padding: 10px 20px;
  width: 100%;
  float: left;
}
.sidemenu_close_icon {
  width: 15px;
}
.sidemenu_brand_icon{
  width: 25px;
}
.menu-sidebar hr{
  width: 90%;
  margin: 0px 15px;
  text-align: center;
  border-top: 1px solid #444444;
  float: left;
}
.myaccount_profile{
  width: 50px;
  float: left;
}
.sidemenu_myaccount_details{
  float: left;
  position: relative;
  left: 10px;
}
.sidemenu_myaccount_details h3{
  color: #fff;
  font-size: 14px;
  margin-top: 6px;
  font-weight: 500;
  margin-bottom: 5px;
  text-align: left;
}
.sidemenu_myaccount_details p{
  color: #ccc;
  font-size: 12px;
  margin-bottom: 0;
}
.sidemenu_myaccount .sidemenu_right_arrow {
  float: right;
  position: relative;
  top: 18px;
  width: 8px;
}
.nav-mobile .menu-container .menu-sidebar .sidemenu_items{
  list-style-type: none;
  padding: 15px 0;
  width: 100%;
  float: left;
}
.nav-mobile .menu-container .menu-sidebar li{
  padding: 10px 20px;
}
.wrapper-header .navbar-nav li a{
  font-size: 12px;
  color: #ffffff;
  font-weight: 400;
}
.nav-mobile .menu-container .menu-sidebar .sidemenu_items li .menuitem_icon{
  margin-right: 10px;
  width: 15px;
}
.menu-sidebar .sidemenu_items .menuitem_right_arrow{
  float: right;
  width: 8px;
}
.wrapper-header .navbar-nav li:hover a{
  color: #ffffff;
}
.nav-mobile .menu-container .menu-sidebar li:hover a{
  color: #2CD7F3;
}
.menu-sidebar .sidemenu_items li .menuitem_default_icon, .menu-sidebar .sidemenu_items li .menuitem_right_default{
  display: inline-block;
}
.menu-sidebar .sidemenu_items li .menuitem_highlighted_icon, .menu-sidebar .sidemenu_items li .menuitem_right_highlighted{
  display: none;
}
.menu-sidebar .sidemenu_items li:hover .menuitem_default_icon, .menu-sidebar .sidemenu_items li:hover .menuitem_right_default{
  display: none;
}
.menu-sidebar .sidemenu_items li:hover .menuitem_highlighted_icon, .menu-sidebar .sidemenu_items li:hover .menuitem_right_highlighted{
  display: inline-block;
}


/*---- My Account -----*/
.inner_header{
    padding: 15px;
    width: 100%;
    float: left;
}
.inner_header img.back_arrow{
    width: 15px;
    float: left;
    cursor: pointer;
    position: absolute;
    top: 70px;
    left: 5px;
}
.inner_header h3{
    margin: 0;
    display: inline-block;
    padding-left: 30px;
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    position: relative;
    top: 3px;
}
.profile_info_details {
  width: 100%;
  float: left;
  /* padding: 15px 0; */
}
.profile_info_details .myprofile_icon{
  width: 60px;
}
.profile_info_details h3{
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 5px;
}
.profile_info_details p{
  color: #999999;
  margin-bottom: 5px;
  font-size: 12px;
}
.profile_info_list{
  width: 100%;
  float: left;
  padding: 15px ;
}
.profile_info_list ul{
    padding-left: 0;
    background-color: #222222;
    border-radius: 6px;
    width: 500px;
    margin: 0 auto;
}
.profile_info_list ul li{
  padding: 15px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
}
.profile_info_list ul li img.profile_info_icon{
  margin-right: 10px;
  width: 20px;
}
.profile_info_list ul li img.right_arrow{
  float: right;
  width: 8px;
}
.profile_info_list ul hr {
  width: 90%;
  margin: 0 auto;
  text-align: center;
  border-color: #333;
}

/* .top_margin{
  margin-top: 55px;
} */
.profile_info_list.go_premimum ul li .profile_brand_icon{
  width: 25px;
  margin-right: 10px;
  position: relative;
  top: -10px;
}
.profile_info_list.go_premimum ul li .go_premimum_info{
  display: inline-block;
}
.profile_info_list.go_premimum ul li .go_premimum_info P{
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 600;
    color: #999;
}
.profile_info_list.go_premimum ul li .go_premimum_info h3{
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 600;
}
.profile_info_list.go_premimum ul .right_arrow{
  position: relative;
  top: 15px;
}


.mysubscriptions_details{
  width: 100%;
  float: left;
  padding: 30px 15px;
}
.mysubscriptions_pack_detail{
  background-color: #222222;
  padding: 30px 15px;
  border-radius: 6px;
  margin: 0 auto;
  width: 500px;
}
.mysubscriptions_details h3{
  font-size: 14px;
  font-weight: 600;
  margin-top: 0;
  color: #2CD7F3;
}
.mysubscriptions_details h2{
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  margin-top: 0;
}
.mysubscriptions_details p{
  font-size: 12px;
  color: #999;
}
.mysubscription_price_details{
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.mysubscription_price_details p{
  font-size: 20px;
  color: #fff;
  margin: 0px;
  margin-right: 10px;
  font-weight: 600;
}

.mysubscriptions_detail_list{
  padding-top: 30px;
}
.mysubscriptions_detail_list .table{
  margin-bottom: 0;
}
.mysubscriptions_detail_list .table td{
  padding: 8px 0;
  font-size: 12px;
}
.mysubscriptions_detail_list .table tr{
  border-top: 1px solid #444;
}
.mysubscriptions_detail_list .table td:nth-child(odd){
  color: #999;
  text-align: left;
}
.mysubscriptions_detail_list .table td:nth-child(even){
  color: #fff;
  text-align: right;
}


.subscription_plans_details {
  width: 100%;
  float: left;
  padding: 15px 30px;
}
.subscription_plans_details h3{
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  margin-top: 0;
}
.subscription_plans_details p{
  font-size: 12px;
  color: #999;
}

.round {
  position: relative;
}

.round .round_checkbox {
  background-color: #999;
  border: 1px solid #999;
  border-radius: 50%;
  cursor: pointer;
  height: 22px;
  left: 15px;
  position: absolute;
  top: 19px;
  width: 22px;
  padding: 0 !important;
}

.round .round_checkbox:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 6px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + .round_checkbox {
  background-color: #2CD7F3;
  border-color: #2CD7F3;
}

.round input[type="checkbox"]:checked + .round_checkbox:after {
  opacity: 1;
}

.subscription_plans_list ul{
  padding-left: 0;
  list-style-type: none;
}
.subscription_plans_list ul li{
  width: 50%;
  display: block;
  margin: 0 auto;
  
}
.subscription_plans_list ul li .round{
  width: 100%;
  float: left;
  border-radius: 6px;
  margin-bottom: 15px;
  cursor: pointer;
  background-color: #222222;
}
.subscription_plans_list ul li.plan_active .round{
  border: 1px solid #2CD7F3;
}
.subscription_plans_list{
  width: 100%;
  float: left;
  padding: 0 15px;
}
.plans_right_arrow{
  float: right;
}
.subscription_plans_list ul li .round label{
  padding: 20px 15px;
  cursor: pointer;
}

.subscription_plans_list ul li .round label.pack_title{
  position: absolute;
  left: 0;
  padding-left: 50px;
  color: #2CD7F3;
}
.subscription_plans_list ul li .round label.plan_price{
  padding-right: 5px;
  color: #fff;
}
.plan_continue_btn{
  width: 100%;
  float: left;
  text-align: center;
  margin: 30px 0;
}
.plan_continue_btn .continue_btn{
    background-color: #2CD7F3;
    border: 1px solid #2CD7F3;
    padding: 10px 25px;
}
.plan_continue_btn .continue_btn:hover{
  background-color: #2CD7F3;
  border: 1px solid #2CD7F3;
  padding: 10px 25px;
  color: #fff;
  font-weight: 500;
}
.btn_right_arrow{
    width: 8px;
    position: relative;
    top: -1px;
    left: 5px;
}




.videojs_player_section .video-js .vjs-big-play-button{
  /* background-color: #2CD7F3; */
  border-radius: 50%;
  /* background-size: 35% 40%; */
  background-size: cover;
  width: 65px;
  height: 65px;
  background-image: url('../../../manorama/assets/images/play_icon_detail.png');
  background-position: center;
}

.videojs_player_section:before {
  content: '';
  position: absolute;
  /* box-shadow: 0px 0px 35px 20px rgb(0 0 0); */
  bottom: 0;
  z-index: 1;
  right: 0;
  left: 0;
}
.mnr_detail_page .movie_details_text .movie_details_title{
  position: relative;
  z-index: 9;
}

.mnr_detail_page .movie_details_text .movie_details_title{
  font-family: "Roboto", Arial;
   font-size: 16px;
   font-weight: 600;
   margin-bottom: 12px;
}
.mnr_detail_page .downloads_play_icon{
  position: absolute;
  top: 7px;
  left: 15px;
  width: 12px;
}
.mnr_detail_page .plan_episode_btn .watch_epi_btn{
  /* width: 60%; */
  background-color: #2CD7F3;
  border: 1px solid #2CD7F3;
  padding: 6px 15px;
  position: relative;
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
  margin-top: 20px;
}
.mnr_detail_page .plan_episode_btn .watch_epi_btn p{
  padding-left: 20px;
}
.mnr_detail_page .plan_episode_btn .watch_epi_btn .epi_title{
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 0;
}
.mnr_detail_page .plan_episode_btn .watch_epi_btn .epi_number{
  font-size: 12px;
  margin-bottom: 0;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.mnr_detail_page .fevitite_options{
  width: 100%;
  float: left;
  margin: 0 auto;
  /* text-align: center; */
}
.mnr_detail_page .fevitite_options ul{
  padding-left: 0;
  list-style-type: none;
  margin: 15px 0;
}
.mnr_detail_page .fevitite_options ul li{
  display: inline-block;
  width: 31%;
  background-color: #222222;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 8px;
  cursor: pointer;
}
.mnr_detail_page .fevitite_options .social_detail{
  text-align: center;
  padding: 10px 3px;
}
.player_watch_epi_btn{
  position: absolute;
  bottom: 0px;
  left: 10px;
}
.social_detail .social_icon{
  display:inline-block;
}
.social_detail .social_icon_highlighted{
  display: inline-block;
}
.social_detail.social_active .social_icon{
  display: inline-block;
}
.social_detail.social_active .social_icon_highlighted{
  display: inline-block;
}
/* .social_detail .share_highlighted_icon,
.social_detail .favorites_highlighted_icon{
  display: none;
}
.social_detail .details_playlist_icon, 
.social_detail:hover .details_share_icon,
.social_detail:hover .details_favorites_icon{
  display: inline-block;
}
.add_play_list .playlist_highlighted_icon, 
.social_detail:hover .share_highlighted_icon, 
.social_detail:hover .favorites_highlighted_icon{
  display: none;
}
.add_play_list.playlist_active .playlist_highlighted_icon, 
.social_detail:hover .share_highlighted_icon,
.social_detail:hover  .favorites_highlighted_icon{
  display: inline-block;
}
.add_play_list.playlist_active .details_playlist_icon, 
.social_detail:hover .details_share_icon,
.social_detail:hover .details_favorites_icon{
  display: none;
} */
/* .social_detail.social_active p{
  color: #2CD7F3;
} */
.p_added_playlist{
  color: #2CD7F3 !important;
}
/* .padd_playlist{
  color: #999;
} */
.p_liked{
  color: #2CD7F3 !important;
}
/* .fevitite_options .social_detail:hover p,
.fevitite_options .social_detail:hover p{
  color: #2CD7F3;
} */
.fevitite_options .social_detail img{
  width: 18px;
}
.mnr_detail_page .fevitite_options .social_detail p{
  margin-bottom: 0;
  padding-top: 7px;
  font-weight: 500;
  font-size: 10px;
  color:#ccc;
}
.mnr_detail_page .epi_description{
  width: 100%;
  float: left;
}
.mnr_detail_page .epi_description p{
  color:#999;
  line-height: 1.6;
  font-size: 14px;
}
.episodes_tabs{
  width: 100%;
  float: left;
  margin-top: 15px;
  /* display: none; */
}
.episodes_tabs .nav.tabs{
  padding-right: 15px;
}
.episodes_tabs .tab_item{
  text-align: center;
}
.episodes_tabs .tab_item h3{
  font-size: 12px;
  font-weight: 500;
  color:#BEBEBE;
  margin: 0;
  padding: 10px 0;
  display: inline-block;
  font-family: "Roboto", Arial;
}
.episodes_tabs .tab_item.tab_active h3{
  color: #2CD7F3;
  border-bottom: 1px solid #2CD7F3;
}
/* .episodes_tabs .tab_item.tab_active {
  border-bottom: 1px solid #2CD7F3;
} */
.episodes_tabs .epi_list .epi_img_part{
  width: 40%;
  float: left;
  position: relative;
}
.epi_details_part {
  float: left;
  width: 60%;
}
.episodes_tabs .epi_list .epi_img_part img{
  width: 100%;
  border-radius: 6px;
}
.epi_details_part{
  padding-left: 10px;
  padding-top: 10px;
}
.epi_details_part h3{
    font-size: 13px;
    color:#E1E1E1;
    margin-top: 0;
    margin-bottom: 5px;
}
.epi_details_part ul{
  padding-left: 0;
  list-style-type: none;
}
.epi_details_part ul li{
  display: inline-block;
  color:#BEBEBE;
  font-size: 12px;
}
.tab_content{
  width: 100%;
  float: left;
}
.tab_content .epi_list{
  padding: 5px 0px 5px 5px;
  width: 100%;
  float: left;
  background-color: #171717;
  margin-bottom: 5px;
}                        
.epi_list .epi_img_part .progress, 
.episodes_img_part .progress {
  padding: 0;
  width: 95%;
  height: 3px;
  overflow: hidden;
  background: #e5e5e5;
  border-radius: 20px;
  margin: 0 auto;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0px;
}

.epi_list .epi_img_part .bar, 
.episodes_img_part .progress .bar {
	position:relative;
  float:left;
  min-width:1%;
  height:100%;
  background:#2CD7F3;
}

.epi_list .epi_img_part .percent {
	position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  margin:0;
  font-family:tahoma,arial,helvetica;
  font-size:12px;
  color:white;
}
.mm_banner .mm_banner_item{
  padding: 0 10px;
}
.mm_banner .mm_banner_item .mm_banner_img{
  width: 100%;
  border-radius: 6px;
}
.mm_banner .slick-slide{
  transition: all 0.4s;
}
.layout_center_carusel .slick-slide.slick-active.slick-center{
  transform: scale(1.1);
  /* transition: all 0.4s; */
}
.mm_banner .slick-slide.slick-active.slick-center{
  transform: scale(1.1);
  /* transition: all 0.4s; */
}
.mm_banner{
    overflow: hidden;
}
.mm_banner .slick-list{
  overflow: visible;
}

.menu_mobile.vfplay_menu_mobile{
  overflow: hidden;
  white-space: nowrap;
  top: 50px;
  bottom: inherit;
  z-index: 99;
  width: 100%;
}
.menu_mobile.vfplay_menu_mobile .menu-listres{
  border-radius: 0px;
  padding: 0;
  background-color: #000 !important;
}
.menu_mobile.vfplay_menu_mobile .menu_list_link.active {
  border-bottom: 2px solid #2CD7F3;
}
.menu_mobile.vfplay_menu_mobile .menu_list_link{
  padding: 8px 18px;
}
.menu_mobile .menu_list_link.active span {
  color: #2CD7F3;
}

.you-may-like_section .owl-carousel .owl-item img{
  border-radius: 6px;
}
.episodes_img_part{
  position: relative;
}
a:focus, a:hover{
  text-decoration: inherit !important;
}




.speed_news_part {
  width: 100%;
  float: left;
}
.speed_news_info{
  margin-bottom: 15px;
  margin: 15px 0;
  cursor: pointer;
}
.speed_news_part .speed_news_img{
  width: 100%;
  border-radius: 10px;
}
.menu_mobile.vfplay_menu_mobile .menu-listres{
  border-radius: 0px;
  padding: 0;
  background-color: #000 !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.menu_mobile.vfplay_menu_mobile .menu-listres::-webkit-scrollbar {
  display: none;
}

.speed_news_part .news_titles{
  width: 100%;
  float: left;
}
.speed_news_part .news_titles h3{
  font-size: 14px;
  color: #222222;
  float: left;
  margin: 8px 0;
  font-weight: 500;
}
.speed_news_part .news_titles p{
  font-size: 12px;
  color: #999;
  float: right;
  margin: 6px 0;
}

/* .white-theme .mainlogo_white, .white-theme .mobile_search_white{
  display: inline-block;
} */
.white-theme .mainlogo_white, .white-theme .mobile_search_white{
  display: none;
}
.dark-theme .mainlogo_white, .dark-theme .mobile_search_white{
  display: none;
}
.dark-theme .mainlogo_dark, .dark-theme .mobile_search_dark{
  display: inline-block;
}

.white-theme .wrapper-header:before{
  box-shadow:inherit;
}
/* .white-theme .hamburger_line, 
.white-theme .hamburger_line::before,
.white-theme .hamburger_line::after{
  background-color: #222222;
} */
.dark-theme .hamburger_line, 
.dark-theme .hamburger_line::before,
.dark-theme .hamburger_line::after{
  background-color: #ffffff;
}
.news_tab_part .news_area{
    width: 80%;
    margin: 0 auto;
    clear: both;
}
.news_tab_part .news_area .news_titles h2{
  font-size: 14px;
  margin: 10px 0;
  color: #222222;;
  float: left;
  font-weight: 500;
}
.news_tab_part .news_area .news_titles img.view_all_arrow{
  float: right;
  width: 12px;
  position: relative;
  top: 10px;
}

.news_tab_part .news_area .mm_banner_item img{
  width: 100%;
  border-radius: 10px;
}
.news_tab_part .news_area .mm_banner_item h3{
    margin: 0;
    font-size: 14px;
    padding-top: 10px;
}
.news_tab_part .news_area .btn_news_part{
  width: 100%;
  float: left;
  text-align: center;
}
.news_tab_part .news_area .btn_news_part .all_news_btn{
  color: #2CD7F3;
  font-size: 14px;
  font-weight: 600;
}
.news_tab_part .news_area .btn_news_part .all_news_btn img{
  width: 12px;
  position: relative;
  left: 5px;
}

.news_tab_part .news_area .mm_banner_item {
  margin-bottom: 15px;
}

.white-theme .wrapper-header{
  background-color: #171717;
}
.dark-theme .wrapper-header{
  background-color: #171717;
  /* background-color: #0F1010; */
}
/* .white-theme .menu_mobile.vfplay_menu_mobile .menu-listres{
  background: #fff !important;
} */
.white-theme #footer .menu_mobile .menu-listres{
  /* background: #fff !important; */
  box-shadow: 1px 1px 5px 1px #999;
}
.white-theme .carousel_body .home_carosel_title h1{
  color: #222222;
}
.white-theme .detail_description_section h1{
  color:#333333;
  font-weight: bold;
}
.white-theme .detail_description_section p{
  color:#666666;
}
.carousel_body .home_carosel_title img.view_all_arrow {
  float: right;
  width: 13px;
  position: relative;
  top: 10px;
}
.news_detail_page .news_detail_banner{
  position: relative;
}
.news_detail_page .news_detail_banner .news_detail_img{
  width: 100%;
}
.news_detail_page .news_detail_banner .news_detail_header {
  position: absolute;
  right: 0;
  left: 0;
  width: 100%;
  float: left;
}
.news_detail_page .news_detail_banner .news_detail_header img.back_img {
  position: relative;
  left: 10px;
  top: 10px;
  width: 20px;
}
.news_detail_page .news_detail_banner .play_icon_detail{
  width: 30px;
  position: absolute;
  right: 15px;
  bottom: -15px;
}
.news_detail_page .news_detail_banner .news_detail_title h2{
  font-size: 14px;
  font-weight: 600;
  color: #222;
  margin-top: 10px;
  margin-bottom: 5px;
}
.news_detail_page .news_detail_banner .news_detail_title p{
  font-size: 12px;
  color: #999;
}

.news_detail_social{
  width: 100%;
  float: left;
}
.news_icon_details .news_social_icon{
  display: inline-block;
}
.news_icon_details .social_highlighted_icon{
  display: none;
}
.news_icon_details.social_active .social_highlighted_icon{
  display: inline-block;
}
.news_icon_details.social_active .news_social_icon{
  display: none;
}
.news_detail_social .fevitite_options ul{
  padding-left: 0;
  list-style-type: none;
  margin: 0 auto;
  text-align: center;
}
.news_detail_social .fevitite_options ul li{
  display: inline-block;
}
.news_detail_social .fevitite_options .news_icon_details {
  text-align: center;
  padding: 6px 4px;
}
.news_detail_social .fevitite_options ul li {
  display: inline-block;
  width: 110px;
  /* background-color: #222222; */
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 4px;
  cursor: pointer;
}
.news_detail_social .fevitite_options .news_icon_details img{
  width: 35px;
}
.news_detail_social .fevitite_options .news_icon_details p{
  font-size: 12px;
  margin-top: 5px;
}
.news_detail_social .fevitite_options .news_icon_details.social_active p{
  color: #2CD7F3;
}
.news_detail_page .news_detail_desc{
  width: 100%;
  float: left;
}
.news_detail_page .news_detail_desc .news_desc_title{
  background-color: #dddddd;
  padding: 10px 15px;
}
.news_detail_page .news_detail_desc .news_desc_title .news_user_profile{
  width: 30px;
  display: inline-block;
}
.news_detail_page .news_detail_desc .news_desc_title h3{
  display: inline-block;
  padding-left: 10px;
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  position: relative;
  top: 2px;
}
.news_detail_page .news_detail_desc .news_description{
  padding: 15px;
}

.news_slider_carousel{
  width: 100%;
  float: left;
  padding: 15px 0;
}
.news_slider_carousel .slick-slide img{
  width: 100%;
  padding: 0 10px;
}

.news_slider_carousel .slick-dots li button:before{
    font-size: 10px;
    line-height: 10px;
    width: 10px;
    height: 4px;
    border-top: 4px solid #222222;
    font-size: 0;
    opacity: .6;
    border-radius: 10px;
}

.news_slider_carousel .slick-dots{
  bottom: -12px;
}
.news_slider_carousel .slick-dots li, .news_slider_carousel  .slick-dots li button{
  width: 10px;
  height: 5px;
}

.news_slider_carousel .slick-dots li.slick-active button:before{
  border-color: #2CD7F3;
  width: 15px;
}
.news_tab_part {
  width: 100%;
  float: left;
}
.header-menu{
  position: absolute;
  left: 225px;
  top: 10px;
}
.header-menu ul{
  color:#ccc;
  font-family: "Roboto", Arial;
}
.header-menu ul li{
  cursor: pointer;
  display: inline-block;
  /* margin: 0px 30px 0px 0px; */
}
.header-menu ul a{
  margin: 0px 30px 0px 0px;
  color:#ccc;
}
.header-menu ul .menu_list_item.active{
  border-bottom: 2px solid #2CD7F3;
  padding-bottom: 5px;
  color:#2CD7F3;
}
.my_profile_page {
  width: 100%;
  float: left;
}
.watchlist_content{
  width: 100%;
  float: left;
  min-height: 300px;
}
.profile_icon .dropdown-menu h5{
  font-size: 10px;
  color:#ccc;
  margin: 0px;
  padding-left: 40px;
}
.profile_icon .dropdown-menu .gopremium{
  border-top: 1px solid #666;
  border-bottom: 1px solid #666;
  padding-top: 5px;
  padding-bottom: 10px;
  margin: 5px 3px;
}
.profile_icon .dropdown-menu .gopremium .gopremium_img{
  padding-top: 14px;
}
.profile_icon .dropdown-menu .profile_login_details{
  padding: 0px 8px;
}
.profile_icon .dropdown-menu .profile_login_details h4{
  color: #fff;
  font-size: 13px;
}
.profile_icon .dropdown-menu .profile_login_details h6{
  font-size: 10px;
  color: #ccc;
}
.share_details .btn .caret{
  display: none;
}
.mnr_detail_page .fevitite_options ul li.share_details {
  padding: 0;
  border: none;
}
.mnr_detail_page .fevitite_options ul li.share_details .dropdown{
  width: 100%;
  text-align: center;
}
.mnr_detail_page .fevitite_options ul li.share_details .dropdown .dropdown-title{
  width: 100%;
  background-color: transparent;
  border: 1px solid #999;
  border-radius: 20px;
  padding: 10px 3px;
}
.share_details .dropdown {
  position: relative;
  display: inline-block;
}

.share_details .dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
}

.share_details .dropdown.share_open .dropdown-content {
  display: block;
}
.share_details .dropdown-list{
  margin: 5px 0 !important;
}
.share_details .dropdown-list li.dropdown-item{
  width: 100% !important;
  background-color: transparent !important;
  border: none;
  padding: 10px 15px;
  margin: 0;
}
.share_details .dropdown-list li.dropdown-item a{
  color: #2CD7F3;
  font-weight: 600;
}
.share_details .dropdown.share_open .dropdown-content{
  padding: 0;
  border-radius: 0 0 10px 10px;
}
.share_details .dropdown-list li.dropdown-item .SocialMediaShareButton >div {
  margin-right: 15px;
  float: left;
  position: relative;
  top: -3px;
}
.share_details .dropdown-list li.dropdown-item .SocialMediaShareButton{
  color: #2CD7F3;
}
.mnr_detail_page .fevitite_options ul li.share_details .dropdown.share_open .dropdown-title{
  border-radius: 20px 20px 0 0;
  background-color: #fff;
}
/* .white-theme .player_section .movie_details, .white-theme .movies_detail_content{
  background-color: #fff !important;
} */
.white-theme .mnr_detail_page .movie_details_text .movie_details_title{
  color: #333333;
}
.white-theme .mnr_detail_page .fevitite_options .social_detail p{
  color: #666;
}

.fevitite_options .social_detail img{
  width: 20px;
  position: relative;
  top: -1px;
}
.flex-dev:hover .premium_badge_lock img,
.carosel_slider .owl-carousel.owl-drag .owl-item:hover .carousel_img .premium_badge_lock img{
  -webkit-transform: scale(1);
    transform: scale(1);
}
.premium_badge_lock {
  right: 2%;
  top: 4%;
}
.layout_center_banner .slick-slider .slick-dots li.slick-active button::before {
  background-color: #2cd7f3;
}
.mysubscriptions_plan_btn{
  text-align: center;
}
.mysubscriptions_plan_btn .desktop_fav_playnow{
  width: 300px;
  text-align: center;
}


.video_center_banner .slick-slider .slick-list video{
  width: 100%;
  border-radius: 16px !important;
  cursor: pointer;
}
.video_center_banner {
  padding: 30px 0px;
  margin-bottom: 10px;
}
video::-webkit-media-controls-fullscreen-button {
  display: none;
}
audio::-webkit-media-controls-timeline, video::-webkit-media-controls-timeline {
    display: none;
}
video::-webkit-media-controls-fullscreen-button, video::-webkit-media-controls-play-button, video::-webkit-media-controls-pausebutton {
  display: none;
}
video::-webkit-media-controls-current-time-display{display: none;}
video::-webkit-media-controls-time-remaining-display {display: none;}
/* video::-webkit-media-controls-toggle-closed-captions-button {display: none;}
video::-webkit-media-controls-volume-slider {display: none;} */
.terms-table tbody{
  color: #ccc;
}
.kbcontest tbody{
  text-align: center;
}
.kbcontest .terms-table tbody td{
  width: fit-content;
}
.terms-table  tbody td{ 
  width: 30%;
}
.redressal_section a{
  display: block;
}
.select_episodes{
  width: 100%;
  float: left;
  text-align: center;
  padding-bottom: 10px;
}
.select_episodes select{
  width: 60%;
  padding: 6px 6px;
  background-color:#171717;
  color:#fff;
  border: 1px solid #6e7583;
  border-radius: 3px;
}
.select_episodes select option{
  font-size: 12px;
}
.player_adds{
  width: 60%;
  float: unset;
  margin: 0 auto;
}
.videojs_player_section .vjs-remaining-time{
  display: none !important;
}
.videojs_player_section .video-js.vjs-480 .vjs-duration{
  display: block !important;
}
.videojs_player_section .video-js .vjs-time-divider{
  display: block !important;
}
.subscription{
  background-color: #2CD7F3;
    width: 260px;
    border-radius: 20px;
    padding: 4px;
    color: #999;
    width: 8%;
    text-align: center;
    position: absolute;
    right: 0px;
    left: 25%;
    margin: 0 auto;
    top: 8px;
}
.subscription_btn{
  outline: none;
  border: none;
  color: #ffff;
  background: none;
  cursor: pointer;
}
.subscription.renew{
  /* background: linear-gradient(-60deg, #fff 56%, #2CD7F3 44%) !important; */
  width: max-content !important;
  font-size: 13px;
  font-weight: 500;
  padding: 4px 18px !important;
}
.subscription.renew .monthtext{
  color: #2CD7F3;
  margin-left: 14px;
}
.info_body {
  color: #ccc;
  font-size: 15px;
  text-align: center;
  padding: 15px 30px;
}
.subscription_alertbox{
  background-color: #2CD7F3;
  width: 130px;
  border-radius: 20px;
  padding: 5px;
  color: #999;
  text-align: center;
  /* position: absolute;
  right: 0px;
  left: 0%; */
  margin: 30px auto;
}
.subscription_content_alertbox .mainlogo_mobile{
  width: 180px;
  margin: auto;
  display: block;
}
.subscription_content_alertbox .modal-content{
  min-height: 220px;
}
.subscription_content_alertbox .modal{
  margin-top: 20px;
}
.tobaccoImg img{
  width: 100%;
  float: left;
  position: absolute;
  z-index: 1;
}
.mysubscriptions_detail_list .cancelAuroRenewal{
  text-align: right;
  color: #2CD7F3;
  margin-top: 15px;
  cursor: pointer;
}
.cancelAutoRenewal.subscription_alertbox{
  background-color: unset !important;
  width: 100% !important;
}
.cancelAutoRenewal .subscription_btn{
  background-color: #2CD7F3;
  border-radius: 16px;
  padding: 5px 20px;
  width: 120px;
  margin-right: 13px;
  cursor: pointer;
}
.subscription_content_alertbox.coupon_landing_popup .mainlogo_mobile{
  display: block !important;
}
.subscription_content_alertbox.coupon_landing_popup .modal-header{
  padding-top: 25px !important;
}
.subscription_content_alertbox.coupon_landing_popup .modal-header button.close{
  color:#fff !important;
  opacity: 1 !important;
}
.subscription_content_alertbox.coupon_landing_popup .info_body p:first-of-type{
  font-size: larger !important;
  color:#2CD7F3 !important;
}
.subscription_content_alertbox.coupon_landing_popup .info_body p{  
  color:#fff !important;
  line-height: 24px !important;
}
.subscription_content_alertbox.coupon_landing_popup .info_body ul{
  text-align: left;
  list-style: disc;
  margin-left: 10px;
  line-height: 30px;
  color: #fff;
}
.subscription_content_alertbox.coupon_landing_popup .info_title h3{
  color:#2CD7F3 !important;
}
.subscription_content_alertbox.coupon_landing_popup .subscription_btn{
  width: 140px !important;
}
@media only screen and (min-width: 1024px) {
  .mnr_detail_page .fevitite_options ul li.share_details .dropdown:hover .dropdown-title {
    border-radius: 20px 20px 0 0;
    background-color: #fff;
  }
  .share_details .dropdown:hover .dropdown-content{
    padding: 0;
    border-radius: 0 0 10px 10px;
  }
  .share_details .dropdown:hover .dropdown-content {
    display: block;
  }
}


@media screen and (max-width: 1024px) and (min-width: 320px){
  .mnr_detail_page .fevitite_options ul li.share_details.share_details_mob .dropdown .dropdown-title{
    border: none;
  }
  .share_details .dropdown-list li.dropdown-item{
      padding: 6px 10px;
      margin: 0;
      text-align: left;
  }
  .share_details .dropdown-content{
    min-width: 100%;
  }
  .subscription{
    display: none;
  }

}
@media screen and (max-width: 480px) and (min-width: 320px){
  .share_details .dropdown-content{
    min-width: 150px;
  }
}


@media screen and (max-width: 767px) and (min-width: 320px){
.viewall_rectangle {
   margin-top: 65px !important;
   padding: 0px;
}
.mainlogo{display: none;}
.carousel_titles .movie_details-div ul li {
   font-size: 9px !important;
}
.carousel_titles h1{
  margin: 7px 0px 0px 0px;
  font-size: 12px;
}
.banner_slider {
  padding-top: 0px !important; 
}
.banner_caption h1{
  display: inherit;
}
.banner_slider .app_rating{
  position: relative;
  top: -25px;
  right: 0;
}
.banner_slider .payment_amount{
  /* position: absolute; */
  right: 4px;
}
.banner_caption .rupee_symbol{
  position: absolute;
  right: 5px;
  top: 18px;
}
.form-group .login_Phone_btn img {
  margin-right: 25px;
}
.menu_list_link.active{
  color:#fff;
}
.menu_mobile .menu_list_item.active .menu_list_link span {
  color: #2CD7F3;
}
/* .viewall_section .horizontalimage {
  height: 211px !important;
} */
/* .sliderbody {
  margin-top: 0px !important;
} */
.signin_logo{
  display: none;
}
.signup_mobile {
  width: 74% !important;
}
#countrycodenumber {
  width: 22%;
}
/* .videojs_player_section .vast-skip-button-text{
  width: 117px;
} */
.videojs_player_section div.vast-skip-button{
  bottom: 50px !important;
}
.device_section button{
  margin: 0px !important;
  font-size: 12px;
}
.device_section button{
  margin: 0px;
  font-size: 12px !important;
}
.device_section {
  margin-top: 120px;
}
/* .top_margin {
  margin-top: 50px;
} */
.profile_info_list ul{
  width: 100%;
}
.mysubscriptions_pack_detail, .subscription_plans_list ul li{
  width: 100%;
}
.episodes_tabs{
  display: block;
}
.mm_banner{
  padding-top: 30px;
}
/* .speed_news_info{
  margin: 15px 0;
} */
.speed_news_info.col-sm-4{
  padding: 0;  
}
.news_tab_part .news_area .news_titles, 
.news_tab_part .news_area, 
.news_tab_part .news_area .slick-slider{
  width: 100%;
  float: left;
  padding: 5px;
}
.sliderbody.topmenushow{
  margin-top: 84px !important;
}
.sliderbody {
  margin-top: 50px !important;
}
.news_slider_carousel .slick-slide img{
  padding: 0 5px;
}
.inner_header img.back_arrow{
  width: 20px;
    float: left;
    cursor: pointer;
    /* position: absolute;
    top: 18%;
    left: 5px; */
    padding: 47px 0px 0px 5px;
}
.inner_header{
  padding: 15px;
  width: 100%;
  float: left;
}

}


@media screen and (max-width: 359px) and (min-width: 320px){
  .carosel_slider .carousel_titles .movie_title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 168px;
  }
  .device_section table{
    font-size: 12px;
  }
}

@media screen and (max-width: 480px) and (min-width: 360px){
  .carosel_slider .carousel_titles .movie_title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 130px;
  }
}
@media screen and (max-width: 900px) and (min-width: 768px){
  .search-hint{
    width: 200px;
  }
}

@media (min-width: 768px) {
  .wrapper-logo img {
    width: 150px;
    margin: 20px 0px;
}
.search-box .navbar-right {
  margin-right: 0px !important;
}
.viewall_arrow{
    display: none;
}
.banner_caption h1{
  display: inherit;
}
.payment_amount.rupee{
  position: absolute;
  right: 20px;
  bottom: 8px;
}
.banner_slider .app_rating{
  right: 10px;
  bottom: 20px;
}
.footer_details.login_section {
    margin-top: 58px;
}
.carosel_slider .movie_details-div ul li{
  font-size: 13px;
}
.wrapper-logo{
  text-align: center;
  position: absolute;
  right: 0;
  left: 0;
  margin: 0px auto;
}
}
     @keyframes shrink-bounce{
       0%{
         transform: scale(1);
       }
       33%{
         transform: scale(.85);
       }
       100%{
         transform: scale(1);    
       }
     }
     @keyframes checkbox-check{
       0%{
         width: 0;
         height: 0;
         border-color:#212121;
         transform: translate3d(0,0,0) rotate(45deg);
       }
       33%{
         width: .2em;
         height: 0;
         transform: translate3d(0,0,0) rotate(45deg);
       }
       100%{
         width: .2em;
         height: .5em;    
         border-color:#212121;
         transform: translate3d(0,-.5em,0) rotate(45deg);
       }
     }
     
